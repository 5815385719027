//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorGastronomy.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterGastronomy from "../../components/CompValuesCenterGastronomy";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageGastronomy from '../global/images/Gastromomie.png'


//SectorGastronomy_____________________________________________
const SectorGastronomy = () => {

  return (
    <div className="SectorGastronomy">
      <CompSectorPicture Title={"Gastronomie"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand – dank intelligenter Planung mit künstlicher Intelligenz."} 
      Picture={ImageGastronomy} PictureAlt={"Gastronomie"}/>
      <div className="SectorGastronomy-Content page-gap">
        <CompValuesCenterGastronomy Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für Gastronomen"} Subheading={""}
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus, reduzieren gleichzeitig den Planungsaufwand und Ihre Retouren"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen Warenwirtschafts & Kassensystemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorGastronomy;
  