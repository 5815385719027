//IMPORT________________________________________________
import React from "react";

// CSS
import './Contact.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompHeadingTextMail from "../../components/CompHeadingTextMail";

//Contact_____________________________________________
const Contact = () => {

  return (
    <div className="Contact page-gap">
      <CompHeading Title={"Kontakt"} Subheading={""}/>
      <CompHeadingTextMail Title={"Kontaktieren Sie uns für ein unverbindliches Erstgespräch\noder fordern Sie Ihre Produktbroschüre an."} 
      Company={"Aixioom Software Solutions GmbH"}
      Phone={"+49 (0) 6026 5013338"}
      Mail={"sales@aixioom.de"}/>
    </div>
  )
};
export default Contact;
  