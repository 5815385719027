// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 550px) {
    .AboutUs .CompPictureTextLeft-Container-Right img {
        height: auto;
    }

    .AboutUs .CompPictureText-Container-Left img {
      height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/aboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;IAChB;;IAEA;MACE,YAAY;IACd;AACJ","sourcesContent":["@media (max-width: 550px) {\n    .AboutUs .CompPictureTextLeft-Container-Right img {\n        height: auto;\n    }\n\n    .AboutUs .CompPictureText-Container-Left img {\n      height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
