//IMPORT________________________________________________
import React from "react";

// CSS
import './Partner.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompLogoText from "../../components/CompLogoText";

// Graphics
import GraphicForecast from '../global/graphics/Forecast.svg'
import GraphicSOP from '../global/graphics/SalesAndOperationsPlanning.svg'
import GraphicPP from '../global/graphics/ProductionPlanning.svg'

// Logos
import LogoMoghaddam from '../global/logos/Logo_Arash_Moghaddam_1.svg'
import LogoLifeStyle from '../global/logos/Logo_LifestyleWebconsulting.svg'
import LogoSwoox from '../global/logos/Logo_Swoox.svg'
import LogoKIMarktplatz from '../global/logos/Logo_KIMarktplatz.svg'


//Partner_____________________________________________
const Partner = () => {

  return (
    <div className="Partner page-gap">
      <CompHeading Title={"Unsere Partner"} Subheading={""}/>
      <div className="Partner-Content">
        <CompLogoText Title={"Privatärztliches Zentrum Aschaffenburg"} Subheading={""} Text={""} Graphic={LogoMoghaddam} GraphicAlt={"Prof. Dr. Arash Moghaddam Logo"}/>
        <CompLogoText Title={"LifeStyle"} Subheading={""} Text={""} Graphic={LogoLifeStyle} GraphicAlt={"LifeStyle Webconsulting Logo"}/>
        <CompLogoText Title={"Swoox.io"} Subheading={""} Text={""} Graphic={LogoSwoox} GraphicAlt={"Swoox.io Logo"}/>
        <CompLogoText Title={"KI Marktplatz"} Subheading={""} Text={""} Graphic={LogoKIMarktplatz} GraphicAlt={"KI Marktplatz"}/>
      </div>
    </div>
  )
};
export default Partner;
  