//IMPORT________________________________________________
import React from "react";

// CSS
import './SolutionSOP.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompPictureText from "../../components/CompPictureText";
import CompValuesSOP from "../../components/CompValuesSOP";
import CompCallToAction from "../../components/CompCallToAction";
import CompHeadingText from "../../components/CompHeadingText";

// Graphics
import GraphicSOP from '../global/graphics/SalesAndOperationsPlanning.svg'

//SolutionSOP_____________________________________________
const SolutionSOP = () => {

  return (
    <div className="SolutionSOP-Content page-gap">
      <CompHeading Title={"Sales- & Operations Planning (S&OP)"} Subheading={""}/>
      <CompPictureText Title={"Intelligente, effiziente Planung für nahtlose Abläufe"} Subheading={""} 
      Text={"Optimieren Sie Ihre Bedarfs- und Produktionsplanung intelligent, indem Sie diese mit den Zielen anderer Unternehmensbereiche wie Vertrieb, Marketing, " + 
        "Logistik und Finanzen sowie den übergeordneten Geschäftszielen des Unternehmens dezentral abstimmen. Profitieren Sie von einfachen Genehmigungsprozessen, " + 
        "datengesteuerter Planung mit intelligenter Analytik und kontinuierlicher Planüberprüfung."} 
        Graphic={GraphicSOP} GraphicAlt={"Intelligente Produktionsplanung"}/>
      <CompHeadingText Title={"Sales- & Operations Planning ist ab Q1 2025 verfügbar."} Subheading={""}/>
    </div>
  )
};
export default SolutionSOP;
  