//IMPORT________________________________________________
import React from "react";

// CSS
import './Imprint.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompImprint from "../../components/CompImprint";

//Imprint_____________________________________________
const Imprint = () => {

  return (
    <div className="Imprint-Content page-gap">
      <CompHeading Title={"Impressum"} Subheading={""}/>
      <CompImprint Title={"Aixioom Software Solutions GmbH"} Text={"Anschrift:\nKatharinenweg 20,\n63762 Großostheim\nTelefon: + 49 (0) 6026 5013338\nE-Mail:  info@aixioom.de"}/>
      <CompImprint Title={"Handelsregister beim Amtsgericht Aschaffenburg:"} Text={"HRB 15978"}/>
      <CompImprint Title={"Umsatzsteuer-Identifikationsnummer gem. § 27a UStG:"} Text={"DE 341062176"}/>
      <CompImprint Title={"Inhaltlich Verantwortlicher gem. § 55 II RStV:"} Text={"Aixioom Software Solutions GmbH (Anschrift s.o.), vertreten durch die Geschäftsführer Stefan Herbst und Fabio Wehmer."}/>
      <CompImprint Title={"Haftung für Inhalte:"} Text={"Wir haben die Inhalte unserer Website mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der zur Verfügung gestellten Inhalte, übernehmen wir keine Gewähr."}/>
      <CompImprint Title={"Haftung für Links:"} Text={"Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen." + 
        "\n\nInformationen gemäß der Verordnung über Informationspflichten für Dienstleistungserbringer (DL-INFO V) und nach dem Telemediengesetz (TMG):" + 
        "\n\nDie Aixioom Software Solutions GmbH (s. Impressum), vertreten durch die Geschäftsführer Stefan Herbst und Fabio Wehmer ist als juristische Person im Handelsregister am Amtsgericht Aschaffenburg unter HRB 15978 eingetragen." + 
        "\n\nDiese Internetseite ist der werbende Internetauftritt der Aixioom Software Solutions und stellt daher eine kommerzielle Kommunikation gem. § 6 TMG dar."}/>
      <CompImprint Title={"Bildnachweise:"} Text={"iStockphoto,\nUnsplash\nPexels"}/>
    </div>
  )
};
export default Imprint;
  