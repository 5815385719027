//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompPictureText.css'

//CompPictureText______________________________________________________________________________
const CompPictureText = (props) => {
    return (
        <div className="CompPictureText-Container">
            <div className="CompPictureText-Container-Left">
                <img src={props.Graphic} alt={props.GraphicAlt}/>
            </div>
            <div className="CompPictureText-Container-Right">
                <div className="CompPictureText-Container-Right-Title">
                    <h2>{props.Title}</h2>
                    <h3>{props.Subheading}</h3>
                </div>
                <div className="CompPictureText-Container-Right-Text">
                    <p>{props.Text}</p>
                </div>
            </div>
        </div>
    )
};
export default CompPictureText;
  