//IMPORT________________________________________________
import React from "react";

// CSS
import './Customer.css';

// Components
import CompHeading from "../../components/CompHeading";
import CompCustomerLogos from "../../components/CompCustomerLogos";
import CompPictureText from "../../components/CompPictureText";

// Images
import ImageWeitblick from '../global/images/Weitblick.jpg'
import ImageBickert from '../global/images/Backhaus_Bickert.jpg'
import ImageMainPost from '../global/images/Main_PostLogistikGruppe.jpg'

//Customer_____________________________________________
const Customer = () => {

  return (
    <div className="Customer page-gap">
      <div className="Customer-Content page-gap-small">
      <CompHeading Title={"Kunden"} Subheading={""}/>
        <CompCustomerLogos Title={""}/>
        <div className="Customer-Content-Examples">
          <CompPictureText Title={"Weitblick"} Subheading={"sustainable workwear"} Text={"Die WEITBLICK® GmbH & Co. KG ist einer der führenden Anbieter von Arbeitsbekleidung und führt derzeit die Lösungen von Aixioom ein."} 
          Graphic={ImageWeitblick} GraphicAlt={"Weitblick"}/>
          <CompPictureText Title={"Backhaus Bickert"} Subheading={"handwerklich. regional. familiär."} Text={"Das Backhaus Bickert, Familienbetrieb in der 4. Generation mit 80 Filialen und eigener Herstellung aller Backwaren. " + 
            "Mit traditionellem Handwerk und modernster Technik. Das Backhaus Bickert nutzt die Künstliche Intelligenz von Aixioom Forecast zur Prognose von Abverkaufsmengen."} Graphic={ImageBickert} GraphicAlt={"Backhaus Bickert"}/>
          <CompPictureText Title={"Main-Post"} Subheading={"Logistikgruppe"} Text={"Die Main-Post Logistikgruppe ist nach der deutschen Post das drittgrößte Postunternehmen in Deutschland und verarbeitet täglich rund 500.000 Sendungen. " +
            "Die Main-Post Logistikgruppe sagt mit Hilfe von Aixioom Forecast die tägliche Sendungsmenge vorher. Auf dieser Basis wird u.a. die optimale Logistik- und Personaleinsatzplanung erstellt."} Graphic={ImageMainPost} GraphicAlt={"Main-Post"}/>
        </div>
      </div>
    </div>
  )
};
export default Customer;
  