//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorRetail.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterRetail from "../../components/CompValuesCenterRetail";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageRetail from '../global/images/LEH.png'


//SectorRetail_____________________________________________
const SectorRetail = () => {

  return (
    <div className="SectorRetail">
      <CompSectorPicture Title={"Einzelhandel"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand – dank intelligenter Prognose Ihrer Abverkäufe mit künstlicher Intelligenz."} 
      Picture={ImageRetail} PictureAlt={"Einzelhandel"}/>
      <div className="SectorRetail-Content page-gap">
        <CompValuesCenterRetail Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für den Einzelhandel"} Subheading={""} 
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen Warenwirtschafts- & Kassensystemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorRetail;
  