//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorPharmacies.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterPharmacies from "../../components/CompValuesCenterPharmacies";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImagePharmacies from '../global/images/Apotheke.png'


//SectorPharmacies_____________________________________________
const SectorPharmacies = () => {

  return (
    <div className="SectorPharmacies">
      <CompSectorPicture Title={"Apotheken"} Subheading={"Mit unserer künstlichen Intelligenz prognostizieren Sie ihre Abverkäufe intelligent und maximieren so Umsätze, minimieren Kosten und schaffen mehr Zeit für die pharmazeutische Beratung ihrer Kunden."} Picture={ImagePharmacies} PictureAlt={"Apotheken"}/>
      <div className="SectorPharmacies-Content page-gap">
        <CompValuesCenterPharmacies Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für Apotheken"} Subheading={""}
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen Apotheken-Softwaresystemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorPharmacies;
  