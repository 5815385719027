//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesForecast.css'

// Graphics
import GraphicFastPlanning from '../pages/global/icons/Icon_FastPlanning.svg';
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg';
import GraphicWarehouse from '../pages/global/icons/Icon_Warehouse.svg';
import GraphicFeature from '../pages/global/icons/Icon_Featurs.svg';

// Components
import CompIconText from "./CompIconText";

//CompValuesForecast______________________________________________________________________________
const CompValuesForecast = (props) => {
    return (
        <div className="CompValuesForecast-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <div className="CompValuesForecast-Container-Icons">
                <CompIconText Graphic={GraphicFastPlanning} Subheading={"95% Zeitersparnis für die operative Planung"} 
                Text={"Aufwändige manuelle Planung gehört der Vergangenheit an. Künstliche Intelligenz hilft den Planungsaufwand um 95% zu reduzieren. Ihre Mitarbeiter sparen so wertvolle Zeit."}/>
                <CompIconText Graphic={GraphicHappyCustomer} Subheading={"Erhöhung der Kundenzufriedenheit durch optimierte Verfügbarkeit"} 
                Text={"Eine optimale Warenverfügbarkeit ist der Schlüssel zur Kundenzufriedenheit. Wenn Sie sicherstellen, dass Ihre Produkte stets verfügbar sind, steigt die Wahrscheinlichkeit, dass Kunden bei Ihnen einkaufen und wiederkommen."}/>
                <CompIconText Graphic={GraphicWarehouse} Subheading={"Reduktion von Stock-outs und Überbeständen"} 
                Text={"Überbestände binden unnötig Kapital. Eine intelligente Bestandsoptimierung, unterstützt durch moderne Planungssoftware, ermöglicht es Ihnen, Ihr Lagerkapazitäten effizient zu nutzen."}/>
                <CompIconText Graphic={GraphicFeature} Subheading={"Modellierung externer Einflussfaktoren"} 
                Text={"Forecast ermittelt anhand von hunderten verschiedenen Einflussfaktoren wie Wetter, konjunkturelle Indikatoren, Ferien- und Feiertage sowie " + 
                "branchenspezifischen Einflussfaktoren automatisch und präzise Iher Bedarfe und minimiert Risiken so proaktiv."}/>
            </div>
        </div>
    )
};
export default CompValuesForecast;
  