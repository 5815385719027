//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesPP.css'

// Graphics
import GraphicGant from '../pages/global/icons/Icon_Gant.svg';
import GraphicTime from '../pages/global/icons/Icon_Time.svg';
import GraphicCalender from '../pages/global/icons/Icon_CalenderTime.svg';
import GraphicTool from '../pages/global/icons/Icon_FastToolChange.svg';

// Components
import CompIconText from "./CompIconText";

//CompValuesPP______________________________________________________________________________
const CompValuesPP = (props) => {
    return (
        <div className="CompValuesPP-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <div className="CompValuesPP-Container-Icons">
                <CompIconText Graphic={GraphicGant} Subheading={"Reduktion des Planungsaufwands um bis zu 50%"} 
                Text={""}/>
                <CompIconText Graphic={GraphicTime} Subheading={"Erhöhung der Kundenzufriedenheit durch optimierte Verfügbarkeit"} 
                Text={""}/>
                <CompIconText Graphic={GraphicCalender} Subheading={"Steigerung der Termintreue"} 
                Text={""}/>
                <CompIconText Graphic={GraphicTool} Subheading={"Um bis zu 40% kürzere Umrüstzeiten"} 
                Text={""}/>
            </div>
        </div>
    )
};
export default CompValuesPP;
  