//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompImprint.css'

//CompImprint______________________________________________________________________________
const CompImprint = (props) => {
    return (
        <div className="CompImprint-Container">
            <h2>{props.Title}</h2>
            <p>{props.Text}</p>
        </div>
    )
};
export default CompImprint;
  