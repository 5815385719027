//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompExplanation.css'

// Components
import FadeInSection from "./utils/FadeInSection";

//CompExplanation______________________________________________________________________________
const CompExplanation = (props) => {

    return (
        <div className="CompExplanation-Container">
            <div className="CompExplanation-Text">
                <h2>{props.Title}</h2>
                <h3>{props.Subheading}</h3>
            </div>
            <FadeInSection>
                <div className="CompExplanation-Graphic">
                    <img src={props.Graphic} alt={props.GraphicAlt}/>
                </div>
            </FadeInSection>
        </div>
    )
};
export default CompExplanation;
  