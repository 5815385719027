//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompLogo.css'

//CompLogo______________________________________________________________________________
const CompLogo = (props) => {

    return (
        <div className="CompLogo-Container">
            <img src={props.Graphic} alt={props.GraphicAlt}/>
            <h3>{props.Subheading}</h3>
        </div>
    )
};
export default CompLogo;
  