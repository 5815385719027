//IMPORT________________________________________________
import React from 'react';

// React Dom
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Home from './pages/home/Home.jsx';
import IndexPages from './pages/index/IndexPages.jsx';
import Platform from './pages/platform/Platform.jsx';
import Solutions from './pages/solutions/Solutions.jsx';
import Sectors from './pages/sectors/Sectors.jsx';
import Customer from './pages/customer/Customer.jsx';
import AboutUs from './pages/aboutUs/AboutUs.jsx';
import Partner from './pages/partner/Partner.jsx';
import Locations from './pages/locations/Locations.jsx';
import Imprint from './pages/imprint/Imprint.jsx';
import DataPrivacy from './pages/dataPrivacy/DataPrivacy.jsx';
import SolutionForecast from './pages/solutions/SolutionForecast.jsx';
import SectorBakery from './pages/sectors/SectorBakery.jsx';
import SectorRetail from './pages/sectors/SectorRetail.jsx';
import SectorWholesale from './pages/sectors/SectorWholesale.jsx';
import SectorFoodProducers from './pages/sectors/SectorFoodProducers.jsx';
import SectorGastronomy from './pages/sectors/SectorGastronomy.jsx';
import SectorTransportation from './pages/sectors/SectorTransportation.jsx';
import SectorPostalService from './pages/sectors/SectorPostalService.jsx';
import SectorEngineering from './pages/sectors/SectorEngineering.jsx';
import SectorWorkwear from './pages/sectors/SectorWorkwear.jsx';
import SectorPharmacies from './pages/sectors/SectorPharmacies.jsx';
import SectorHealthcare from './pages/sectors/SectorHealthcare.jsx';
import ScrollToTop from './components/utils/ScrollToTop.jsx';
import Sustainability from './pages/sustainability/Sustainability.jsx';
import Contact from './pages/contact/Contact.jsx';
import SolutionPP from './pages/solutions/SolutionPP.jsx';
import SolutionSOP from './pages/solutions/SolutionSOP.jsx';
import Career from './pages/career/Career.jsx';

//App___________________________________________________
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<IndexPages/>}>
            <Route index element={<Home/>}/>
            <Route path="/platform" element={<Platform/>}/>
            <Route path="/solutions" element={<Solutions/>}/>
            <Route path="/forecast" element={<SolutionForecast/>}/>
            <Route path="/production-planning" element={<SolutionPP/>}/>
            <Route path="/sales-and-operations-planning" element={<SolutionSOP/>}/>
            <Route path="/sectors" element={<Sectors/>}/>
            <Route path="/bakery" element={<SectorBakery/>}/>
            <Route path="/retail" element={<SectorRetail/>}/>
            <Route path="/wholesale" element={<SectorWholesale/>}/>
            <Route path="/food-producers" element={<SectorFoodProducers/>}/>
            <Route path="/gastronomy" element={<SectorGastronomy/>}/>
            <Route path="/transportation" element={<SectorTransportation/>}/>
            <Route path="/postal-service" element={<SectorPostalService/>}/>
            <Route path="/engineering" element={<SectorEngineering/>}/>
            <Route path="/workwear" element={<SectorWorkwear/>}/>
            <Route path="/pharmacies" element={<SectorPharmacies/>}/>
            <Route path="/healthcare" element={<SectorHealthcare/>}/>
            <Route path="/customer" element={<Customer/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/partner" element={<Partner/>}/>
            <Route path="/locations" element={<Locations/>}/>
            <Route path="/imprint" element={<Imprint/>}/>
            <Route path="/data-privacy" element={<DataPrivacy/>}/>
            <Route path="/sustainability" element={<Sustainability/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/career" element={<Career/>}/>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>    
  );
}

export default App;
