//IMPORT________________________________________________
import React from "react";

// CSS
import './Solutions.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompCustomerVoice from '../../components/CompCustomerVoice'
import CompCallToAction from '../../components/CompCallToAction'
import TeaserSolution from '../../components/Teaser/TeaserSolution'
import TeaserSolutionRight from '../../components/Teaser/TeaserSolutionRight'

// Graphics
import GraphicForecast from '../global/graphics/Forecast.svg'
import GraphicSOP from '../global/graphics/SalesAndOperationsPlanning.svg'
import GraphicPP from '../global/graphics/ProductionPlanning.svg'

// Images
import ImageDummy from '../global/images/Dummy.png'

//Solutions_____________________________________________
const Solutions = () => {

  return (
    <div className="Solutions-Content page-gap">
      <CompHeading Title={"Lösungen"} Subheading={"Unsere intelligenten Lösungen revolutionieren Ihre Supply Chain und Produktionsplanung."}/>
      <TeaserSolution Title={"Forecast"} Subheading={"Bereits heute wissen, was morgen verkauft wird."} 
      Graphic={GraphicForecast} GraphicAlt={"Aixioom Forecast"} ButtonText={"Zur Lösung"} ButtonLink={"/forecast"}/>
      <TeaserSolutionRight Title={"Production Planning (PP)"} Subheading={"Intelligente Produktionsplanung für maximale Effizienz."} 
      Graphic={GraphicPP} GraphicAlt={"Production Planning (PP)"} ButtonText={"Zur Lösung"} ButtonLink={"/production-planning"}/>
      <TeaserSolution Title={"Sales- & Operations Planning (S&OP)"} Subheading={"Intelligente, effiziente Planung für nahtlose Abläufe."} 
      Graphic={GraphicSOP} GraphicAlt={"Aixioom Sales- & Operations Planning (S&OP)"} ButtonText={"Zur Lösung"} ButtonLink={"/sales-and-operations-planning"}/>

      {/* <CompCustomerVoice Title={"Kundenstimme"} Text={"A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like."} CustomerImg={ImageDummy} GraphicAlt={"Customer"}/> */}
      <CompCallToAction Title={"Starten Sie noch heute mit Aixioom"} Subheading={"Kontaktieren Sie jetzt eine(n) unserer Expert*innen und fordern Sie eine individuelle Demo an."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
    </div>
  )
};
export default Solutions;
  