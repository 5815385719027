//IMPORT________________________________________________
import React from "react";

import { Link } from "react-router-dom";

// CSS
import './Sectors.css';

// Images
import sectorBakeryImg from '../global/images/Bakery.jpg';
import sectorEngineeringImg from '../global/images/Maschinen_und_Anlagenbau.png';
import sectorFoodProducersImg from '../global/images/Lebensmittelproduzenten.png';
import sectorFoodRetailImg from '../global/images/LEH.png';
import sectorWholesaleImg from '../global/images/Großhandel.jpg';
import sectorGastronomyImg from '../global/images/Gastromomie.png';
import sectorHealthcareImg from '../global/images/Gesundheitswesen.png';
import sectorPharmaciesImg from '../global/images/Apotheke.png';
import sectorPostalServiceImg from '../global/images/Postdienstleister.png';
import sectorTransportationImg from '../global/images/Transportlogistik.png';
import sectorWorkwearImg from '../global/images/Workwear.png';

// Components
import CompHeading from '../../components/CompHeading' 
import CompCallToAction from '../../components/CompCallToAction'


//Sectors_____________________________________________
const Sectors = () => {
  const [sectorImage, setsectorImage] = React.useState(sectorBakeryImg);

  return (
    <div className="Sectors page-gap">
      <CompHeading Title={"Unsere maßgeschneiderten Branchenlösungen"} Subheading={"Entdecken Sie unsere branchenübergreifenden KI-Lösungen"}/>
      <div className="Sectors-Content">
        <div className="Sectors-Content-Links">
          <Link to="/bakery" onMouseEnter={() => setsectorImage(sectorBakeryImg)}>Lebensmittel & Bäckereien {">"}</Link>
          <Link to="/retail" onMouseEnter={() => setsectorImage(sectorFoodRetailImg)}>Einzelhandel {">"}</Link>
          <Link to="/wholesale" onMouseEnter={() => setsectorImage(sectorWholesaleImg)}>Großhandel {">"}</Link>
          <Link to="/food-producers" onMouseEnter={() => setsectorImage(sectorFoodProducersImg)}>Lebensmittelproduzenten {">"}</Link>
          <Link to="/gastronomy" onMouseEnter={() => setsectorImage(sectorGastronomyImg)}>Gastronomie {">"}</Link>
          <Link to="/engineering" onMouseEnter={() => setsectorImage(sectorEngineeringImg)}>Maschinen- und Anlagenbau {">"}</Link>
          <Link to="/transportation" onMouseEnter={() => setsectorImage(sectorTransportationImg)}>Transportlogistik {">"}</Link>
          <Link to="/postal-service" onMouseEnter={() => setsectorImage(sectorPostalServiceImg)}>Postdienstleister {">"}</Link>
          <Link to="/workwear" onMouseEnter={() => setsectorImage(sectorWorkwearImg)}>Workwear {">"}</Link>
          <Link to="/pharmacies" onMouseEnter={() => setsectorImage(sectorPharmaciesImg)}>Apotheken {">"}</Link>
          <Link to="/healthcare" onMouseEnter={() => setsectorImage(sectorHealthcareImg)}>Gesundheitswesen {">"}</Link>
        </div>
        <img src={sectorImage} alt="Branchenbild"></img>
      </div>

      <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
      Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
    </div>
  )
};
export default Sectors;
  