//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompHeading.css'

//CompHeading______________________________________________________________________________
const CompHeading = (props) => {
    return (
        <div className="CompHeading-Container">
            <h1>{props.Title}</h1>
            <h3>{props.Subheading}</h3>
        </div>
    )
};
export default CompHeading;
  