//IMPORT_______________________________________________________________________________________
import React from "react";

// Links für Router
import { Link } from "react-router-dom";

// CSS
import './CompEmployee.css'

// Icons
import IconLinkedIn from '../pages/global/icons/Icon_LinkedIn_hover.svg'

//CompEmployee______________________________________________________________________________
const CompEmployee = (props) => {
    return (
        <div className="CompEmployee-Container">
            <div className="CompEmployee-Container-Image">
                <img src={props.Graphic} alt={props.GraphicAlt}/>
            </div>
            <div className="CompEmployee-Container-Text">
                <h3>{props.Name}</h3>
                <p>{props.Text}</p>
                <Link className="CompEmployee-Container-LinkedIn" to={props.LinkedIn}>
                    <svg width="31" height="31" viewBox="0 0 31 31">
                        <path d="M26.9919 0.356537C27.8759 0.356537 28.7238 0.707726 29.3489 1.33285C29.974 1.95797 30.3252 2.80582 30.3252 3.68987V27.0232C30.3252 27.9073 29.974 28.7551 29.3489 29.3802C28.7238 30.0053 27.8759 30.3565 26.9919 30.3565H3.65853C2.77447 30.3565 1.92663 30.0053 1.30151 29.3802C0.676385 28.7551 0.325195 27.9073 0.325195 27.0232V3.68987C0.325195 2.80582 0.676385 1.95797 1.30151 1.33285C1.92663 0.707726 2.77447 0.356537 3.65853 0.356537H26.9919ZM26.1585 26.1899V17.3565C26.1585 15.9155 25.5861 14.5335 24.5671 13.5146C23.5482 12.4956 22.1662 11.9232 20.7252 11.9232C19.3085 11.9232 17.6585 12.7899 16.8585 14.0899V12.2399H12.2085V26.1899H16.8585V17.9732C16.8585 16.6899 17.8919 15.6399 19.1752 15.6399C19.794 15.6399 20.3875 15.8857 20.8251 16.3233C21.2627 16.7609 21.5085 17.3544 21.5085 17.9732V26.1899H26.1585ZM6.79186 9.6232C7.53447 9.6232 8.24666 9.3282 8.77176 8.8031C9.29686 8.278 9.59186 7.56581 9.59186 6.8232C9.59186 5.2732 8.34186 4.00654 6.79186 4.00654C6.04484 4.00654 5.32841 4.30329 4.80018 4.83152C4.27195 5.35975 3.9752 6.07618 3.9752 6.8232C3.9752 8.3732 5.24186 9.6232 6.79186 9.6232ZM9.10853 26.1899V12.2399H4.49186V26.1899H9.10853Z"/>
                    </svg>
                </Link>
            </div>
        </div>
    )
};
export default CompEmployee;
  