//IMPORT________________________________________________
import React from "react";

// CSS
import './Sustainability.css';

// Images
import ImageHead from '../global/images/sustainability-image-head.png'
import ImageLeft from '../global/images/Nachhaltigkeit02.png'
import ImageRight from '../global/images/Nachhaltigkeit01.png'

// Components
import CompHeading from '../../components/CompHeading' 
import CompHeadingText from '../../components/CompHeadingText' 
import CompTwoPictures from "../../components/CompTwoPictures";

//Sustainability_____________________________________________
const Sustainability = () => {

  return (
    <div className="Sustainability page-gap">
      <CompHeading Title={"Nachhaltigkeit"} Subheading={"Unsere Lösungen sorgen für mehr Nachhaltigkeit."}/>
      <img className="Sustainability-Image" src={ImageHead} alt={"Nachhaltigkeit"}/>
      <CompHeadingText Title={"Ressourceneffizienz und Emissionsreduktion"} 
      Text={"KI kann dazu beitragen, den Ressourcenverbrauch zu optimieren. Durch präzise Prognosen und intelligente Algorithmen lassen sich " + 
      "Material- und Energieflüsse besser steuern. Die Reduzierung von Verschwendung, z. B. durch eine exakte Warenverfügbarkeit, minimiert den ökologischen Fußabdruck. " +
       "Weniger Überproduktion und weniger Abfall bedeuten weniger Ressourcenverbrauch und geringere Umweltauswirkungen."}/>
      <CompTwoPictures GraphicLeft={ImageLeft} GraphicRight={ImageRight} GraphicLeftAlt={"Nachhaltigkeit"} GraphicRightAlt={"Nachhaltigkeit"}/>
      <CompHeadingText Title={"Effizienz und Wettbewerbsvorteile"} 
      Text={"KI-gestützte Planung ermöglicht eine präzisere Bedarfsprognose und optimierte Lagerhaltung. Dadurch werden Kosten gesenkt und die Effizienz gesteigert. "+
      "Unternehmen, die KI nutzen, sind agiler und können schneller auf Veränderungen reagieren. Das stärkt ihre Wettbewerbsfähigkeit. " +
      "Nicht nur in der Lebensmittelbranche ist eine exakte Warenverfügbarkeit entscheidend. KI kann dazu beitragen, " + 
      "Überproduktion zu vermeiden und die Lieferkette effizienter zu gestalten. Weniger verschwendete Lebensmittel bedeuten nicht nur ökonomische Einsparungen, " + 
      "sondern auch einen Beitrag zum Umweltschutz."}/>
    </div>
  )
};
export default Sustainability;
  