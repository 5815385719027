//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesCenter.css'

// Graphics
import GraphicRevenue from '../pages/global/icons/Icon_GrowingRevenue.svg'
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg'
import GraphicTruck from '../pages/global/icons/Iocon_Truck.svg'
import GraphicFastPlanning from '../pages/global/icons/Icon_FastPlanning.svg'
import GraphicTime from '../pages/global/icons/Icon_Time.svg'

// Components
import CompIconTextCenter from "./CompIconTextCenter"
import FadeInSection from "./utils/FadeInSection";

//CompValuesCenterTransport______________________________________________________________________________
const CompValuesCenterTransport = (props) => {
    return (
        <div className="CompValuesCenter-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
                <div className="CompValuesCenter-Container-Icons">
                    <CompIconTextCenter Graphic={GraphicRevenue} Subheading={"Umsätze steigern und sichern"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicHappyCustomer} Subheading={"Erhöhung der Kundenzufriedenheit durch schnelle Lieferung"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicTruck} Subheading={"Optimierte Auslastung von Transportmitteln"} Text={""}/>  
                    <CompIconTextCenter Graphic={GraphicTime} Subheading={"Ausreichend Zeit zur Absprache mit dem Kunden"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicFastPlanning} Subheading={"95% Zeitersparnis in der operativen Planung durch Automatisierung von Routineaufgaben"} Text={""}/> 
                </div>
        </div>
    )
};
export default CompValuesCenterTransport;
  