//IMPORT_________________________________________________________________________
import React from "react";

// Links für Router
import { Link } from "react-router-dom";

// CSS
import './ButtonIconGrey.css'

//ButtonIconGrey_____________________________________________________________________
const ButtonIconGrey = (props) => {
    return (
        <Link className="ButtonIconGrey-Container" to={props.Link}>
{/*         <div className="ButtonIconGrey-Container-Icon">
                <svg width="24" height="25" viewBox="0 0 24 25">
                    <mask id="mask0_90_791" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                        <rect y="0.133301" width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_90_791)">
                        <path d="M12 16.1333L7 11.1333L8.4 9.6833L11 12.2833V4.1333H13V12.2833L15.6 9.6833L17 11.1333L12 16.1333ZM6 20.1333C5.45 20.1333 4.97917 19.9375 4.5875 19.5458C4.19583 19.1541 4 18.6833 4 18.1333V15.1333H6V18.1333H18V15.1333H20V18.1333C20 18.6833 19.8042 19.1541 19.4125 19.5458C19.0208 19.9375 18.55 20.1333 18 20.1333H6Z"/>
                    </g>
                </svg>
            </div> */}
            <div className="ButtonIconGrey-Container-Text">Produktbroschüre anfordern</div>
        </Link>
    )
};
export default ButtonIconGrey;
  