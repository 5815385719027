//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompTextButtons.css'

// Components
import ButtonGrey from "./Buttons/ButtonGrey";
import ButtonIconGrey from "./Buttons/ButtonIconGrey";

//CompTextButtons______________________________________________________________________________
const CompTextButtons = (props) => {
    return (
        <div className="CompTextButtons-Container">
            <h3>{props.Subheading}</h3>
            <p>{props.Text}</p>
            <div className="CompTextButtons-Container-Buttons">
                <ButtonIconGrey Text={props.ButtonIconGreyText} Link={props.ButtonIconGreyLink}/>
                <ButtonGrey Text={props.ButtonGreyText} Link={props.ButtonGreyLink}/>
            </div>
        </div>
    )
};
export default CompTextButtons;
  