//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorFoodProducers.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterFoodProducers from "../../components/CompValuesCenterFoodProducers";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageFoodProducers from '../global/images/Lebensmittelproduzenten.png'



//SectorFoodProducers_____________________________________________
const SectorFoodProducers = () => {

  return (
    <div className="SectorFoodProducers">
      <CompSectorPicture Title={"Lebensmittelproduzenten"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand  – dank intelligenter Planung mit künstlicher Intelligenz."} Picture={ImageFoodProducers} PictureAlt={"Lebensmittelproduzenten"}/>
      <div className="SectorFoodProducers-Content page-gap">
        <CompValuesCenterFoodProducers Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für Lebensmittlproduzenten"} Subheading={""} 
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP- und MES-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorFoodProducers;
  