//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompIconText.css'

// Components
import FadeInSection from "./utils/FadeInSection";

//CompIconText______________________________________________________________________________
const CompIconText = (props) => {
    return (
        <div className="CompIconText-Container">
            <FadeInSection>
                <div className="CompIconText-Container-Icon">
                    <img src={props.Graphic} alt={props.GraphicAlt}/>
                </div>
                <h3>{props.Subheading}</h3>
                <p>{props.Text}</p>
            </FadeInSection>
        </div>
    )
};
export default CompIconText;
  