//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './FadeInSection.css'

const FadeInSection = (props) => {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => domRef.current ? observer.unobserve(domRef.current) : null;
    }, []);
    return (
        <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
        >
        {props.children}
        </div>
    );
}
export default FadeInSection;