//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompMap.css'

//CompMap______________________________________________________________________________
const CompMap = (props) => {

    return (
        <div className="CompMap-Container">
            <h2>{props.Title}</h2>
            <img src={props.Graphic} alt={props.GraphicAlt}/>
        </div>
    )
};
export default CompMap;
  