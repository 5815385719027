//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesCenter.css'

// Graphics
import GraphicRevenue from '../pages/global/icons/Icon_GrowingRevenue.svg'
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg'
import GraphicWarehouse from '../pages/global/icons/Icon_Warehouse.svg'
import GraphicFastPlanning from '../pages/global/icons/Icon_FastPlanning.svg'
import GraphicPlanning from '../pages/global/icons/Icon_Gant.svg'
import GraphicTime from '../pages/global/icons/Icon_Time.svg'
import GraphicCalenderTime from '../pages/global/icons/Icon_CalenderTime.svg'
import GraphicTool from '../pages/global/icons/Icon_FastToolChange.svg'

// Components
import CompIconTextCenter from "./CompIconTextCenter"
import FadeInSection from "./utils/FadeInSection";

//CompValuesCenterFoodProducers______________________________________________________________________________
const CompValuesCenterFoodProducers = (props) => {
    return (
        <div className="CompValuesCenter-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
                <div className="CompValuesCenter-Container-Icons">
                    <CompIconTextCenter Graphic={GraphicRevenue} Subheading={"Umsätze um bis zu 5% steigern und sichern"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicWarehouse} Subheading={"Reduktion von Stockouts um bis zu 80% & Reduktion Ihrer Überbestände um durchschnittlich 30 bis 40%"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicHappyCustomer} Subheading={"Erhöhung der Kundenzufriedenheit durch optimale Warenverfügbarkeit"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicFastPlanning} Subheading={"95% Zeitersparnis in der operativen Planung durch Automatisierung von Routineaufgaben"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicPlanning} Subheading={"Reduktion des Planungsaufwands um bis zu 50%"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicTime} Subheading={"Reduktion von Durchlaufzeiten"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicCalenderTime} Subheading={"Steigerung der Termintreue"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicTool} Subheading={"Um bis zu 40% kürzere Umrüstzeiten"} Text={""}/>
                </div>
        </div>
    )
};
export default CompValuesCenterFoodProducers;
  