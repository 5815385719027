//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompIconTextCenter.css'

// Components
import FadeInSection from "./utils/FadeInSection";

//CompIconTextCenter______________________________________________________________________________
const CompIconTextCenter = (props) => {
    return (
            <div className="CompIconTextCenter-Container">
                <FadeInSection>
                    <div className="CompIconTextCenter-Container-Icon">
                    <img src={props.Graphic} alt={props.GraphicAlt}/>
                    </div>
                    <h3>{props.Subheading}</h3>
                    <p>{props.Text}</p>
                </FadeInSection>
            </div>

    )
};
export default CompIconTextCenter;
  