//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorHealthcare.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterHealthcare from "../../components/CompValuesCenterHealthcare";
import CompSolutions from "../../components/CompSolutions";
import CompLogo from "../../components/CompLogo";

// Graphics
import ImageHealthcare from '../global/images/Gesundheitswesen.png'
import CompCustomerVoice from "../../components/CompCustomerVoice";

// Images
import ImageMoghaddam from '../global/images/Prof_Dr_Arash_Moghaddam.png'

// Logos
import LogoMoghaddam from '../global/logos/Logo_Arash_Moghaddam_1.svg'


//SectorHealthcare_____________________________________________
const SectorHealthcare = () => {

  return (
    <div className="SectorHealthcare">
      <CompSectorPicture Title={"Gesundheitswesen"} Subheading={"Optimale Patientenversorgung durch intelligente Ressourcenplanung."} 
      Picture={ImageHealthcare} PictureAlt={"Gesundheitswesen"}/>
      <div className="SectorHealthcare-Content page-gap">
        <CompValuesCenterHealthcare Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für das Gesundheitswesen"} Subheading={""}
        ButtonText={"Prognostizieren Sie das Patientenaufkommen präzise und verbessern Sie so Ihre Personalplanung, " + 
        "erhöhen Sie Ihre Mitarbeiterzufriendenheit und optimieren Sie kostspielige OP-Kapazitäten."}
        ForecastDocLink={"/contact"}/>
        <div className="SectorHealthcare-Content-Coop">
          <CompHeadingText Title={"Entwickelt in Kooperation mit Prof. Dr. Arash Moghaddam"}/>
          <CompLogo Graphic={LogoMoghaddam} GraphicAlt={"Prof. Dr. Arash Moghaddam Logo"}/>
          <CompCustomerVoice Title={""} Text={"“Die Veränderungen der Krankenhauslandschaft wird die Notaufnahmen vor neue Herausforderungen stellen. " +
            "Ungeplantes Patientenaufkommen führt zu Engpässen in der Notfallversorgung, Qualitätsverlusten in der Patientenversorgung, " +
            "überlastetem Personal und Strapazierung der räumlichen sowie technischen Möglichkeiten. " +
            "Dies führt trotz exponentiell steigender Kosten zu Unzufriedenheit bei Patienten & Personal. " +
            "Mit Forecast können Sie die personellen, räumlichen und technischen Ressourcen in der zentralen Notaufnahme (ZNA) präzise planen und optimieren.”"} 
            CustomerImg={ImageMoghaddam} GraphicAlt={"Prof. Dr. Arash Moghaddam"}/>
        </div>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} 
        ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorHealthcare;
  