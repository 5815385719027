//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './TeaserSolutionRight.css'

// Components
import ButtonGrey from '../Buttons/ButtonGrey.jsx'
import FadeInSection from "../utils/FadeInSection.jsx";

//TeaserSolutionRight______________________________________________________________________________
const TeaserSolutionRight = (props) => {
    return (
        <div className="TeaserSolutionRight-Container">
            <FadeInSection>
                <div className="TeaserSolutionRight-Container-Left">
                    <img src={props.Graphic} alt={props.GraphicAlt} />
                </div>
            </FadeInSection>
            <div className="TeaserSolutionRight-Container-Right">
                <h2>{props.Title}</h2>
                <h3>{props.Subheading}</h3>
                <ButtonGrey Text={props.ButtonText} Link={props.ButtonLink}/>
            </div>
        </div>
    )
};
export default TeaserSolutionRight;
  