//IMPORT________________________________________________
import React from "react";

// CSS
import './Platform.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompExplanation from '../../components/CompExplanation'
import CompCustomerVoice from '../../components/CompCustomerVoice'
import CompCallToAction from '../../components/CompCallToAction'

// Graphics
import GraphicDataAnaPlat from '../global/graphics/Img_DataAnalyticsPlattform.svg'
import GraphicBigDataPlat from '../global/graphics/Img_Datacube.svg'
import GraphicIntegration from '../global/graphics/Img_ITIntegration.svg'
import GraphicScreen from '../global/graphics/IMG_Anpassbarkeit.svg'

// Images
import ImageDummy from '../global/images/Dummy.png'

//Platform_____________________________________________
const Platform = () => {

  return (
    <div className="Platform-Content page-gap">
      <CompHeading Title={"Die intelligente Enterprise Planungs-Plattform für Supply Chain und Produktion"} Subheading={""}/>
      <CompExplanation Title={"Die intelligente Data Analytics Planungsplattform"} 
      Subheading={"Das Herzstück unserer Plattform ist eine Künstliche Intelligenz. Mit unserer leistungsstarken KI können Sie Daten effizient verarbeiten, " + 
      "wertvolle Erkenntnisse gewinnen und bessere Entscheidungen treffen. Die Plattform ermöglicht eine intuitive Dateninterpretation und -visualisierung."} 
      Graphic={GraphicDataAnaPlat} GraphicAlt={"Data Analytics Planungsplattform"}/>
      <CompExplanation Title={"Die Big Data Planungsplattform"} 
      Subheading={"Unsere KI-Planungsplattform nutzt Big Data, um präzise Analysen durchzuführen. Mithilfe unserer intelligenten Algorithmen werden historische Daten und hunderte verschiedene Einflüsse berücksichtigt. " + 
      "Dabei nutzen wir große Datenmengen, um fundierte Entscheidungen zu ermöglichen."} Graphic={GraphicBigDataPlat} GraphicAlt={"Big Data Planungsplattform"}/>
      <CompExplanation Title={"Nahtlose Integration von Unternehmens- und externen Datenquellen"} 
      Subheading={"Durch die nahtlose Integration verschiedener Datenquellen – sowohl innerhalb als auch außerhalb Ihres Unternehmens – ermöglichen wir Ihnen eine präzise und effiziente Planung. " + 
      "Nutzen Sie die Kraft der Künstlichen Intelligenz, um schnell fundierte Entscheidungen zu treffen."} Graphic={GraphicIntegration} GraphicAlt={"Integration"}/>
      <CompExplanation Title={"Die Planungslösungen, die sich Ihren Bedürfnissen anpassen"} 
      Subheading={"Unsere Lösungen sind schnell und flexibel anpassbar, damit Sie perfekt zu Ihrem Unternehmen und Ihren Bedürfnissen passen, denn jeder unserer Kunden ist einzigartig."} Graphic={GraphicScreen} GraphicAlt={"Anpassbarkeit"}/>
      {/* <CompCustomerVoice Title={"Kundenstimme"} Text={"A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like."} CustomerImg={ImageDummy} GraphicAlt={"Customer"}/> */}
      <CompCallToAction Title={"Starten Sie noch heute mit Aixioom"} Subheading={"Kontaktieren Sie jetzt eine(n) unserer Expert*innen und fordern Sie eine individuelle Demo an."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
    </div>
  )
};
export default Platform;
  