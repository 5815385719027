//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompCustomerLogos.css'

// Components
import FadeInSection from '../components/utils/FadeInSection'

// Graphics
import LogoBickert from '../pages/global/logos/Logo-Backhaus_Bickert.jpg';
import LogoWeitblick from '../pages/global/logos/Logo-Weitblick.jpg';
import LogoMainPost from '../pages/global/logos/Logo-Main_Post_Logistikgruppe.jpg';
import LogoOnkelTomsWirtshaus from '../pages/global/logos/Logo_OnkelToms.jpg';

//CompCustomerLogos______________________________________________________________________________
const CompCustomerLogos = (props) => {
    return (
        <div className="CompCustomerLogos-Container">
            <div className="CompCustomerLogos-Title">
                <h2>{props.Title}</h2>
            </div>
            <FadeInSection>
                <div className="CompCustomerLogos-Logos">
                    <img className="CompCustomerLogos-Logo-Bickert" src={LogoBickert} alt="Backhaus Bickert Logo"/>
                    <img className="CompCustomerLogos-Logo-Weitblick" src={LogoWeitblick} alt="Weitblick Logo"/>
                    <img className="CompCustomerLogos-Logo-MainPost" src={LogoMainPost} alt="Main-Post Logisitkgruppe Logo"/>
                    <img className="CompCustomerLogos-Logo-TomsWirtshaus" src={LogoOnkelTomsWirtshaus} alt="Onkel Toms Wirtshaus Logo"/>
                </div>
            </FadeInSection>
        </div>
    )
};
export default CompCustomerLogos;
  