//IMPORT________________________________________________
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// CSS
import './Career.css';

// Images
import ImageLeft from '../global/images/career-image-left.png'
import ImageRight from '../global/images/career-image-right.png'

// Components
import CompHeading from '../../components/CompHeading' 
import CompHeadingText from "../../components/CompHeadingText";
import CompTwoPictures from "../../components/CompTwoPictures";

//Career_____________________________________________
const Career = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="Career page-gap">
      <CompHeading Title={"Karriere"} Subheading={""}/>

      <div className="Career-Accordion">
        <CompHeadingText Title={"Wir suchen"} Text={"Zur Verstärkung unseres Teams suchen wir ab sofort:"}/>
        
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordion-1-content"
            id="accordion-1-header"
          >
            <Typography><b>Datenwissenschaftler / Data Scientist (m/w/d)</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Deine Aufgaben:</b><br/>Datenanalyse und Zeitreihenanalyse: Du wirst große Datensätze analysieren und Muster in Zeitreihendaten erkennen.<br/>
  Data Cleaning und Feature Engineering: Du wirst Daten bereinigen, um qualitativ hochwertige Informationen zu gewinnen, und Features entwickeln, um Modelle zu verbessern.<br/>
  Feintuning von KI-Modellen für Forecasting und Planung: Du wirst bestehende KI-Modelle optimieren, um genaue Prognosen und Planungen zu ermöglichen.<br/>
  Enge Zusammenarbeit mit dem Kunden: Du wirst eng mit unseren Kunden zusammenarbeiten, um ihre spezifischen Anforderungen zu verstehen und maßgeschneiderte Lösungen zu entwickeln. Hierfür wirst du Einblick in die Geschäftsbereiche unserer Kunden unterschiedlichster Branchen bekommen.<br/><br/>
  
  <b>Dein Profil:</b><br/>
  Abgeschlossene Hochschulbildung (Master, Promotion oder erste Berufserfahrung von Vorteil), jeweils vorzugsweise mit (informations-)technischem, Data Science, Machine Learning oder quantitativem Schwerpunkt.<br/>
  Erste Erfahrungen im Bereich Geschäftsprozesse im Kontext Data Analytics.<br/>
  Theoretische oder praktische Kenntnisse im Kontext Machine Learning und Transformermodelle.<br/>
  Leidenschaft und Begeisterung für Data Analytics, Artificial Intelligence und für die Verbesserung der Planung unserer Kunden.<br/>
  Teamfähigkeit, Kommunikationsstärke und eine eigenständige, lösungsorientierte Arbeitsweise.<br/>
  Analytisch-Konzeptionelle Fähigkeiten.<br/>
  Souveränes und professionelles Auftreten sowie Erfahrung im Umgang mit agilen Arbeitsmethoden und gängigen Projektmanagement-Tools runden dein Profil ab.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordion-1-content"
            id="accordion-1-header"
          >
            <Typography><b>Werksstudent im Bereich Datenwissenschaften / Data Scientist (m/w/d)</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Deine Aufgaben:</b><br/>Unterstützung bei der Daten- und Zeitreihenanalyse: Du wirst große Datensätze analysieren und Muster in Zeitreihendaten erkennen.<br/>
            Mitarbeit im Bereich Data Cleaning und Feature Engineering: Du wirst Daten bereinigen, um qualitativ hochwertige Informationen zu gewinnen, und Features entwickeln, um Modelle zu verbessern.<br/>
            Unterstützung beim Feintuning von KI-Modellen für Forecasting und Planung: Du wirst bestehende KI-Modelle optimieren, um genaue Prognosen und Planungen zu ermöglichen.<br/>
            Enge Zusammenarbeit mit dem Kunden: Du wirst Einblick in die Geschäftsbereiche unserer Kunden unterschiedlichster Branchen bekommen.<br/><br/>
  
  <b>Dein Profil:</b><br/>
  Laufendes Studium, vorzugsweise Master mit (informations-)technischem, Data Science, Machine Learning oder quantitativem Schwerpunkt.<br/>
  Erste Erfahrungen im Bereich Geschäftsprozesse im Kontext Data Analytics von Vorteil.<br/>
  Theoretische oder praktische Kenntnisse im Kontext Machine Learning und Transformermodelle.<br/>
  Leidenschaft und Begeisterung für Data Analytics, Artificial Intelligence und für die Verbesserung der Planung unserer Kunden.<br/>
  Teamfähigkeit, Kommunikationsstärke und eine eigenständige, lösungsorientierte Arbeitsweise.<br/>
  Analytisch-Konzeptionelle Fähigkeiten.<br/>
  Erfahrung im Umgang mit agilen Arbeitsmethoden und gängigen Projektmanagement-Tools runden dein Profil ab.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordion-2-content"
            id="accordion-2-header"
          >
            <Typography><b>Full Stack Softwareentwickler / Developer (m/w/d)</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Deine Aufgaben:</b><br/>Entwicklung und Wartung von Frontend-Anwendungen u.a. mit React und JavaScript.<br/>
            Implementierung und Pflege von Backend-Systemen.<br/>
            Anbindung von Datenquellen und APIs (zumeist REST) an Datenbanken.<br/>
            Erstellung und Optimierung von Web-Crawlern.<br/>
            Arbeit an Projekten im Bereich künstlicher Intelligenz (KI) und großer Sprachmodelle (LLM).<br/>
            Zusammenarbeit mit anderen Entwicklern und Teams zur kontinuierlichen Verbesserung unserer Softwarelösungen.<br/><br/>
  
  <b>Dein Profil:</b><br/>
  Abgeschlossenes Studium der Informatik (Bachelor, Master) oder eine vergleichbare Qualifikation.<br/>
  Erste Berufserfahrung in der Softwareentwicklung ist von Vorteil.<br/>
  Leidenschaft und Begeisterung für moderne Softwareentwicklung und innovative Technologien.<br/>
  Erfahrung in der Entwicklung von Frontend-Anwendungen mit React und JavaScript.<br/>
  Fundierte Kenntnisse in Backend-Technologien und -Frameworks, insbesondere in JavaScript. Idealerweise Erfahrung in Containerisierung und Clustering, z.B. mit Kubernetes.<br/>
  Erfahrung im Umgang mit APIs (z.B. REST) sowie mit relationalen und idealerweise auch Graph-Datenbanken.<br/>
  Kenntnisse in der Erstellung und Optimierung von Web-Crawlern.<br/>
  Interesse und idealerweise erste Erfahrung im Bereich künstlicher Intelligenz und großer Sprachmodelle.<br/>
  Analytisch-Konzeptionelle Fähigkeiten.<br/>
  Teamfähigkeit, Kommunikationsstärke und eine eigenständige, lösungsorientierte Arbeitsweise.<br/>
  Erfahrung im Umgang mit agilen Arbeitsmethoden und gängigen Projektmanagement-Tools runden dein Profil ab.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordion-2-content"
            id="accordion-2-header"
          >
            <Typography><b>Werksstudent im Bereich Full Stack Softwareentwicklung / Developer (m/w/d)</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Deine Aufgaben:</b><br/>Unterstützung bei der Entwicklung und Wartung von Frontend-Anwendungen, insbesondere mit React und JavaScript.<br/>
            Mitarbeit bei der Implementierung und Pflege von Backend-Systemen.<br/>
            Anbindung von Datenquellen und APIs (zumeist REST) an Datenbanken.<br/>
            Unterstützung bei der Erstellung und Optimierung von Web-Crawlern.<br/>
            Mitarbeit an Projekten im Bereich künstlicher Intelligenz (KI) und großer Sprachmodelle (LLM).<br/>
            Zusammenarbeit mit anderen Entwicklern und Teams zur kontinuierlichen Verbesserung unserer Softwarelösungen.<br/><br/>
  
  <b>Dein Profil:</b><br/>
  Laufendes Studium der Informatik oder eines vergleichbaren Studiengangs.<br/>
  Erste praktische Erfahrungen in der Softwareentwicklung sind von Vorteil.<br/>
  Leidenschaft und Begeisterung für moderne Softwareentwicklung und innovative Technologien.<br/>
  Kenntnisse in der Entwicklung von Frontend-Anwendungen mit React und JavaScript.<br/>
  Wissen in Backend-Technologien und -Frameworks, insbesondere in JavaScript. Idealerweise erste Erfahrungen in Containerisierung und Clustering, z. B. mit Kubernetes.<br/>
  Kenntnisse im Umgang mit APIs (z.B. REST) sowie mit relationalen und idealerweise auch Graph-Datenbanken.<br/>
  Interesse an der Erstellung und Optimierung von Web-Crawlern.<br/>
  Interesse und idealerweise erste Erfahrungen im Bereich künstlicher Intelligenz und großer Sprachmodelle.<br/>
  Analytisch-Konzeptionelle Fähigkeiten.<br/>
  Teamfähigkeit, Kommunikationsstärke und eine eigenständige, lösungsorientierte Arbeitsweise.<br/>
  Erste Erfahrungen im Umgang mit agilen Arbeitsmethoden und gängigen Projektmanagement-Tools sind von Vorteil.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <CompHeadingText Title={"Über uns"} Text={"Wir sind ein junges, innovatives Unternehmen, \
        das sich auf die Entwicklung modernster KI-basierter Softwarelösungen spezialisiert hat. \
        Mit unseren KI-basierten Softwarelösungen möchten wir die betriebliche Effizienz maximieren und gleichzeitig ökonomische und ökologische Nachhaltigkeit fördern. \
        Mit einem Fokus auf menschzentrierte KI revolutionieren wir die Unternehmensplanung, -führung und Entscheidungsfindung durch datengestützte Ansätze. \
        Zusätzlich bieten wir Data Science Dienstleistungen und die Implementierung großer Sprachmodelle für verschiedene Anwendungsfälle an. \
        Unser Team besteht aus leidenschaftlichen Entwicklern, Datenwissenschaftlern (Data Scientist) und Dateningenieuren (Data Engineer) \
        die gemeinsam an spannenden Projekten arbeiten."}/>

      <CompTwoPictures GraphicLeft={ImageLeft} GraphicRight={ImageRight}/>

      <CompHeadingText Title={"Wir bieten"} Text={"> Die Möglichkeit durch eigene Ideen die digitale Transformation branchenübergreifend mitzugestalten.\
      \n> Dynamisch wachsendes Umfeld. \n> Flexibles Arbeitszeitmodell sowie 100% im Homeoffice.\
      \n> Ein dynamisches und motiviertes Team. \n> Spannende und herausfordernde Projekte. \n> Eine attraktive Vergütung."}/>

      <CompHeadingText Title={"Werde Teil unseres Teams"} Text={"Wir freuen uns über die Bewerbung von Menschen jeden Geschlechts. \
        Deine aussagefähigen Bewerbungsunterlagen, aus denen wir deinen Werdegang, deine Qualifikation, \
        deinen Gehaltswunsch sowie den nächstmöglichen Eintrittstermin ersehen können sendest du bitte ausschließlich als PDF an: career@aixioom.de"}/>
    </div>
  )
};
export default Career;
  