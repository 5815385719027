// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
}

.Content {
  margin-top: 100px;
  display: flex;
}

.Pages {
  width: 100%;
  height: 100%;
}
@media(min-width: 1501px) {
  .Pages {
    padding: 0px var(--padding-aixioom-large-main);
  }
}
@media(max-width: 1500px) {
  .Pages {
    padding: 0px var(--padding-aixioom-medium-main);
  }
}
@media(max-width: 550px) {
  .Pages {
    padding: 0px var(--padding-aixioom-small-main);
  }
}

.CookieConsent {
  background: var(--color-aixioom-blue-main) !important;
}
.CookieConsent Button {
  background: var(--color-aixioom-linear-gradient) !important;
  border-radius: var(--border-radius-aixioom-medium-button) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/index/IndexPages.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE;IACE,8CAA8C;EAChD;AACF;AACA;EACE;IACE,+CAA+C;EACjD;AACF;AACA;EACE;IACE,8CAA8C;EAChD;AACF;;AAEA;EACE,qDAAqD;AACvD;AACA;EACE,2DAA2D;EAC3D,oEAAoE;AACtE","sourcesContent":[".App {\n  width: 100%;\n}\n\n.Content {\n  margin-top: 100px;\n  display: flex;\n}\n\n.Pages {\n  width: 100%;\n  height: 100%;\n}\n@media(min-width: 1501px) {\n  .Pages {\n    padding: 0px var(--padding-aixioom-large-main);\n  }\n}\n@media(max-width: 1500px) {\n  .Pages {\n    padding: 0px var(--padding-aixioom-medium-main);\n  }\n}\n@media(max-width: 550px) {\n  .Pages {\n    padding: 0px var(--padding-aixioom-small-main);\n  }\n}\n\n.CookieConsent {\n  background: var(--color-aixioom-blue-main) !important;\n}\n.CookieConsent Button {\n  background: var(--color-aixioom-linear-gradient) !important;\n  border-radius: var(--border-radius-aixioom-medium-button) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
