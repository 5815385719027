//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './TeaserPlattform.css'

// Components
import ButtonGrey from '../../components/Buttons/ButtonGrey.jsx'

//TeaserPlattform______________________________________________________________________________
const TeaserPlattform = (props) => {
    return (
        <div className="TeaserPlattform-Container">
            <div className="TeaserPlattform-Container-Left">
                <h1>{props.Title}</h1>
                <h3>{props.Subheading}</h3>
                <ButtonGrey Text={"Unsere Lösungen"} Link={props.ButtonLink}/>
            </div>
            <div className="TeaserPlattform-Container-Right">
                <img src={props.Graphic} alt={props.GraphicAlt} />
            </div>
        </div>
    )
};
export default TeaserPlattform;
  