//IMPORT________________________________________________
import React from "react";

// CSS
import './Home.css'

// Graphics
import GraphicPlanningPlattform from '../global/graphics/Img_PlanningPlattform.svg';

// Components
import TeaserPlattform from '../../components/Teaser/TeaserPlattform.jsx'
import TeaserSectors from '../../components/Teaser/TeaserSectors.jsx'
import TeaserMembership from '../../components/Teaser/TeaserMembership.jsx'
import CompCallToAction from "../../components/CompCallToAction.jsx";
import CompCustomerLogos from "../../components/CompCustomerLogos.jsx";

//Home__________________________________________________
const Home = () => {
  return (
    <div className="Home-Content landingpage-gap">
      <TeaserPlattform Title={"Die intelligente Planungsplattform"} Subheading={"Mit unseren KI-Lösungen setzen Sie neue Maßstäbe in Ihrer Planung."} Graphic={GraphicPlanningPlattform} 
      GraphicAlt={"Die intelligente Planungsplattform"} ButtonLink={"/solutions"}/>
      <CompCustomerLogos Title={"Kunden"}/>
      <TeaserSectors Title={"Entdecken Sie unsere maßgeschneiderten Lösungen für Ihre Branche"} Subheading={"Unsere KI-Lösungen sind branchenübergreifend verfügbar."} 
      ButtonText={"Zu den Branchen"} ButtonLink={"/sectors"}/>
      <CompCallToAction Title={"Starten Sie noch heute mit Aixioom"} Subheading={"Kontaktieren Sie jetzt eine(n) unserer Expert*innen und fordern Sie eine individuelle Demo an."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      <TeaserMembership Title={"Mitglied im"}/>
    </div>
  )
};
export default Home;
  