//IMPORT________________________________________________
import React from "react"

// CSS
import './AboutUs.css'

/// Logos
import LogoAixioom from '../global/logos/aixioom-logo.png'

// Graphics
import GraphicReliability from '../global/images/Values/reliability.png'
import GraphicRespect from '../global/images/Values/respect.png'
import GraphicEfficiency from '../global/images/Values/efficiency.svg'
import GraphicFuture from '../global/images/Values/future-viability.png'
import GraphicSustainability from '../global/images/Values/sustainability.png'

// Components
import CompLogo from "../../components/CompLogo"
import CompHeadingText from "../../components/CompHeadingText"
import CompMgmtBoard from "../../components/CompMgmtBoard"
import CompPictureText from '../../components/CompPictureText'
import CompPictureTextLeft from "../../components/CompPictureTextLeft"

//AboutUs_____________________________________________
const AboutUs = () => {

  return (
    <div className="AboutUs page-gap">
      <CompLogo Subheading={"Erfahren Sie mehr über uns!"} Graphic={LogoAixioom}/>
      <CompHeadingText Title={"Was wir machen"} Text={"Unsere Vision ist es, Unternehmen jeder Größe und Branche Zugang zu den Potentialen der künstlichen Intelligenz zu bieten, " + 
        "um die betriebliche Effizienz zu maximieren und gleichzeitig die ökonomische und ökologische Nachhaltigkeit zu fördern. Dabei setzen wir auf menschenzentrierte KI."}/>
      <CompHeadingText Title={"Was wir anders machen"} Text={"Die Kunst ist nicht, Fachchinesisch zu beherrschen. Sondern es in Ihre Sprache zu übersetzen. " + 
        "Wir sind kein großer, schwerfälliger Tanker, sondern ein Schnellboot - denn wir bieten Ihnen schnelle und flexible Lösungen, angepasst an Ihre Bedürfnisse. " + 
        "Daher ergänzen wir unsere Produktpalette um individuell angepasste Lösungen, die perfekt zu Ihrem Unternehmen und Ihren Herausforderungen passen, " +
        "denn jeder unserer Kunden ist einzigartig. Mit unseren KI-Algorithmen und -Technologien setzen wir neue Maßstäbe."}/>
      <CompMgmtBoard Title={"Wer wir sind - Geschäftsführung"} 
      Text={"Wir sind genau wie unsere Lösungen: schnell, effizient, zuverlässig – und sehr unkompliziert im Umgang. Wir stehen auf Zahlen & Fakten!"}/>
      <CompHeadingText Title={"Unsere Werte"} 
      Text={"Materielle Werte spielen in der heutigen Welt die zentrale Rolle im  Leben vieler Menschen. Im Gegensatz dazu, verlieren immaterielle Werte immer mehr an Bedeutung. " + 
      "Wir finden, dass sich dieses Verhältnis wieder  umkehren muss. Diese Werte treiben uns an:"}/>
      <CompPictureText Title={"Authentizität & Verlässlichkeit"} Subheading={""} Text={"Ein Handschlag steht für uns auch heute noch für Verlässlichkeit."} 
          Graphic={GraphicReliability}/>
      <CompPictureTextLeft Title={"Respekt & Wertschätzung"} Subheading={""} Text={"Mit unseren Mitarbeitern und Kunden respektvoll umgehen, prägen bei uns die Werte Respekt & Wertschätzung."} 
      Graphic={GraphicRespect}/>
      <CompPictureText Title={"Effektivität & Effizienz"} Subheading={""} Text={"Für uns bedeuten Effektivität & Effizienz, die richtigen Dinge zu tun und die Dinge richtig zu tun."} 
          Graphic={GraphicEfficiency}/>
      <CompPictureTextLeft Title={"Zukunftsfähigkeit"} Subheading={""} Text={"Nicht an alten Strukturen festzuhalten, sondern dynamisch und flexibel den Sprung an die Spitze zu meistern, definiert für uns Zukunftsfähigkeit."} 
      Graphic={GraphicFuture}/>
      <CompPictureText Title={"Nachhaltigkeit"} Subheading={""} Text={"Für uns beinhaltet Nachhaltigkeit, ökologische, ökonomische und soziale Aspekte zu achten und zu wahren."} 
          Graphic={GraphicSustainability}/>
    </div>
  )
};
export default AboutUs;
  