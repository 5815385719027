//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorWorkwear.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterWorkwear from "../../components/CompValuesCenterWorkwear";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageWorkwear from '../global/images/Workwear.png'


//SectorWorkwear_____________________________________________
const SectorWorkwear = () => {

  return (
    <div className="SectorWorkwear">
      <CompSectorPicture Title={"Workwear"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand  – dank intelligenter Planung mit künstlicher Intelligenz."} Picture={ImageWorkwear} PictureAlt={"Workwear"}/>
      <div className="SectorWorkwear-Content page-gap">
        <CompValuesCenterWorkwear Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für die Bekleidungsindustrie"} Subheading={""}
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP- und MES-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={""}/>
      </div>
    </div>
  )
};
export default SectorWorkwear;
  