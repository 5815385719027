//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompCallToAction.css'

// Components
import ButtonGreen from './Buttons/ButtonGreen.jsx'

//CompCallToAction______________________________________________________________________________
const CompCallToAction = (props) => {
    return (
        <div className="CompCallToAction-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <ButtonGreen Text={props.ButtonText} Link={props.ButtonLink}/>
        </div>
    )
};
export default CompCallToAction;
  