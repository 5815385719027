//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompLocationText.css'

//CompLocationText______________________________________________________________________________
const CompLocationText = (props) => {
    return (
        <div className="CompLocationText-Container">
            <div className="CompLocationText-Container-Left">
                <div className="CompLocationText-Container-Left-Title">
                    <h2>{props.Title}</h2>
                    <h3>{props.Subheading}</h3>
                    <p>{props.TextLeft}</p>
                </div>
            </div>
            <div className="CompLocationText-Container-Right-Text">
                <p>{props.TextRight}</p>
            </div>

        </div>
    )
};
export default CompLocationText;
  