//IMPORT________________________________________________
import React from 'react'
import './IndexPages.css'

// Header & Footer
import Header from '../global/Header.jsx'
import Footer from '../global/Footer.jsx'

// Cookie Consent
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";

import {Outlet,useLocation} from "react-router-dom"

//IndexPages____________________________________________
const indexPages = () => {

  // Set current URL as initiale state
  const location = useLocation();
  const [menuID, setMenuID] = React.useState(location.pathname);
  const activeMenu = (parameter) => setMenuID(parameter);

  return ( 
    <div className="App">
        <CookieConsent buttonText="Akzeptieren" enableDeclineButton declineButtonText="Nicht akzeptieren">
          Diese Webseite verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern.
        </CookieConsent>
        <div className="Header">
          <Header activeMenu={activeMenu} menuID={menuID}/>
        </div>
        <div className='Content'>
          <div className='Pages'>
            <Outlet/>
          </div>
        </div>
        <div className='Footer'>
          <Footer activeMenu={activeMenu} menuID={menuID} resetCookies={resetCookieConsentValue}/>
        </div>
    </div>
  )
};
export default indexPages;
