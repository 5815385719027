//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompSectorPicture.css'

//CompSectorPicture______________________________________________________________________________
const CompSectorPicture = (props) => {
    return (
        <div className="CompSectorPicture-Container stack-parent">
            <div className="CompSectorPicture-Container-Picture stack-child">
                <img src={props.Picture} alt={props.PictureAlt} />
            </div>
            <div className="CompSectorPicture-Container-Text stack-child">
                <h1>{props.Title}</h1>
                <h3>{props.Subheading}</h3>
            </div>
        </div>
    )
};
export default CompSectorPicture;
  