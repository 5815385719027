//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompMgmtBoard.css'

// Components
import CompEmployee from "./CompEmployee";

// Images
import EmployeeStefanHerbst from '../pages/global/images/Stefan_Herbst.png'
import EmployeeFabioWehmer from '../pages/global/images/Fabio_Wehmer.png'
import EmployeeSilkeWenk from '../pages/global/images/Silke_Wenk.png'

//CompMgmtBoard______________________________________________________________________________
const CompMgmtBoard = (props) => {
    return (
        <div className="CompMgmtBoard-Container">
            <div className="CompMgmtBoard-Container-Text">
                <h2>{props.Title}</h2>
                <p>{props.Text}</p>
            </div>
            <div className="CompMgmtBoard-Container-Employee">
                <CompEmployee Graphic={EmployeeStefanHerbst} GraphicAlt={"Stefan Herbst"} Name={"Stefan Herbst"} Text={"CEO"} LinkedIn={"https://www.linkedin.com/in/stefan-herbst-699607216?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"}/>
                <CompEmployee Graphic={EmployeeFabioWehmer} GraphicAlt={"Fabio Wehmer"} Name={"Fabio Wehmer"} Text={"CTO"} LinkedIn={"https://de.linkedin.com/in/fabio-wehmer-6b2a231a7?trk=org-employees"}/>
                <CompEmployee Graphic={EmployeeSilkeWenk} GraphicAlt={"Dr. Silke Wenk"} Name={"Dr. Silke Wenk"} Text={"CFO"} LinkedIn={"https://de.linkedin.com/in/dr-silke-wenk-519271247"}/>
            </div>
        </div>
    )
};
export default CompMgmtBoard;
  