//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompTwoPictures.css'

//CompTwoPictures______________________________________________________________________________
const CompTwoPictures = (props) => {
    return (
        <div className="CompTwoPictures-Container">
            <img className="CompTwoPictures-Container-Left" src={props.GraphicLeft} alt={props.GraphicLeftAlt}/>
            <img className="CompTwoPictures-Container-Right" src={props.GraphicRight} alt={props.GraphicRightAlt}/>
        </div>
    )
};
export default CompTwoPictures;
  