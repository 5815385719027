//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesCenter.css'

// Graphics
import GraphicPatient from '../pages/global/icons/Icon_Patient.svg'
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg'
import GraphicPersons from '../pages/global/icons/Icon_Persons.svg'
import GraphicRadiology from '../pages/global/icons/Icon_Radiology.svg'

// Components
import CompIconTextCenter from "./CompIconTextCenter"
import FadeInSection from "./utils/FadeInSection";

//CompValuesCenterHealthcare______________________________________________________________________________
const CompValuesCenterHealthcare = (props) => {
    return (
        <div className="CompValuesCenter-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
                <div className="CompValuesCenter-Container-Icons">
                    <CompIconTextCenter Graphic={GraphicPatient} Subheading={"Präzise Prognose des Patientenaufkommens"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicPersons} Subheading={"Bessere Personalplanung"} Text={""}/> 
                    <CompIconTextCenter Graphic={GraphicRadiology} Subheading={"Erhöhung der Patientensicherheit"} Text={""}/>  
                    <CompIconTextCenter Graphic={GraphicHappyCustomer} Subheading={"Steigerung der Mitarbeiterzufriedenheit"} Text={""}/>
                </div>
        </div>
    )
};
export default CompValuesCenterHealthcare;
  