//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './TeaserMembership.css'

// Logos
import LogoAiAssociation from '../../pages/global/graphics/ki-bundesverband-guetesiegel.png';
import LogoBitkom from '../../pages/global/logos/bitkom-logo.png';

// Components
import FadeInSection from "../utils/FadeInSection.jsx";

//TeaserMembership______________________________________________________________________________
const TeaserMembership = (props) => {
    return (
        <div className="TeaserMembership-Container">
            <h2>{props.Title}</h2>
            <div className="TeaserMembership-Container-Icons">
                <FadeInSection>
                    <div className="TeaserMembership-Container-Icon">
                        <img src={LogoBitkom} alt={"Bitkom"} />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="TeaserMembership-Container-Icon">
                        <img src={LogoAiAssociation} alt={"KI Bundesverband"} />
                    </div>
                </FadeInSection>
            </div>
        </div>
    )
};
export default TeaserMembership;
  