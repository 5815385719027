// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompTwoPictures-Container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

@media(min-width: 1501px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-large-element-big);
    }
    .CompTwoPictures-Container img {
        width: 50%;
        border-radius: var(--border-radius-aixioom-large-element);
    }
}
@media(max-width: 1500px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-medium-element-big);
    }
    .CompTwoPictures-Container img {
        width: 50%;
        border-radius: var(--border-radius-aixioom-medium-element);
    }
}
@media(max-width: 550px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-small-element-big);
    }
    .CompTwoPictures-Container img {
        width: 100%;
        border-radius: var(--border-radius-aixioom-small-element);
    }
    .CompTwoPictures-Container-Right {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CompTwoPictures.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,yCAAyC;IAC7C;IACA;QACI,UAAU;QACV,yDAAyD;IAC7D;AACJ;AACA;IACI;QACI,0CAA0C;IAC9C;IACA;QACI,UAAU;QACV,0DAA0D;IAC9D;AACJ;AACA;IACI;QACI,yCAAyC;IAC7C;IACA;QACI,WAAW;QACX,yDAAyD;IAC7D;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".CompTwoPictures-Container {\n    display: flex;\n    align-items: flex-start;\n    align-self: stretch;\n}\n\n@media(min-width: 1501px) {\n    .CompTwoPictures-Container {\n        gap: var(--gap-aixioom-large-element-big);\n    }\n    .CompTwoPictures-Container img {\n        width: 50%;\n        border-radius: var(--border-radius-aixioom-large-element);\n    }\n}\n@media(max-width: 1500px) {\n    .CompTwoPictures-Container {\n        gap: var(--gap-aixioom-medium-element-big);\n    }\n    .CompTwoPictures-Container img {\n        width: 50%;\n        border-radius: var(--border-radius-aixioom-medium-element);\n    }\n}\n@media(max-width: 550px) {\n    .CompTwoPictures-Container {\n        gap: var(--gap-aixioom-small-element-big);\n    }\n    .CompTwoPictures-Container img {\n        width: 100%;\n        border-radius: var(--border-radius-aixioom-small-element);\n    }\n    .CompTwoPictures-Container-Right {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
