//IMPORT________________________________________________
import React from "react";

// CSS
import './Locations.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompMap from "../../components/CompMap";
import CompLocationText from "../../components/CompLocationText";

// Graphics
import GraphicMap from '../global/icons/Aixioom_Locations.svg'



//Locations_____________________________________________
const Locations = () => {

  return (
    <div className="Locations page-gap">
      <CompHeading Title={"Unsere Standorte"} Subheading={""}/>
      <div className="Locations-Content page-gap-small">
        <CompMap Title={""} Graphic={GraphicMap}/>
        <CompLocationText Title={"Hauptsitz Großostheim"} Subheading={"bei Aschaffenburg"} TextLeft={""} TextRight={"63762 Großostheim"}/>
        <CompLocationText Title={"Büro Berlin"} Subheading={"Schöneberg"} TextLeft={""} TextRight={"10825 Berlin"}/>
        <CompLocationText Title={"Büro Lindau"} Subheading={"am Bodensee"} TextLeft={""} TextRight={"88131 Lindau (Bodensee)"}/>
      </div>
    </div>
  )
};
export default Locations;
  