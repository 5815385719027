//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompHeadingText.css'

//CompHeadingText______________________________________________________________________________
const CompHeadingText = (props) => {
    return (
        <div className="CompHeadingText-Container">
            <h2>{props.Title}</h2>
            <p>{props.Text}</p>
        </div>
    )
};
export default CompHeadingText;
  