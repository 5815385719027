// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectorHealthcare .CompCustomerVoice-ImgText img {
    object-fit: cover;
    object-position: 100% 0;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}
.SectorHealthcare .CompLogo-Container {
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/sectors/SectorHealthcare.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,qBAAqB;IACrB,6BAA6B;AACjC;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".SectorHealthcare .CompCustomerVoice-ImgText img {\n    object-fit: cover;\n    object-position: 100% 0;\n    transform: scaleX(-1);\n    -webkit-transform: scaleX(-1);\n}\n.SectorHealthcare .CompLogo-Container {\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
