//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorPostalService.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterPostalService from "../../components/CompValuesCenterPostalService";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImagePostalService from '../global/images/Postdienstleister.png'


//SectorPostalService_____________________________________________
const SectorPostalService = () => {

  return (
    <div className="SectorPostalService">
      <CompSectorPicture Title={"Postdienstleister"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand  – dank intelligenter Prognose Ihrer Tonnagen mit künstlicher Intelligenz."} Picture={ImagePostalService} PictureAlt={"Postdienstleister"}/>
      <div className="SectorPostalService-Content page-gap">
        <CompValuesCenterPostalService Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für Postdienstleister"} Subheading={""}
          ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
          ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP- und MES-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorPostalService;
  