//IMPORT________________________________________________
import React from "react";

// CSS
import './DataPrivacy.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompImprint from "../../components/CompImprint";

//DataPrivacy_____________________________________________
const DataPrivacy = () => {

  return (
    <div className="DataPrivacy-Content page-gap">
      <CompHeading Title={"Datenschutz"} Subheading={""}/>
      <CompImprint Title={"Datenschutzerklärung"} Text={"Die nachfolgende Datenschutzerklärung gilt für die Nutzung unseres Online-Angebots https://www.aixioom.ai (nachfolgend „Website“)." +
        "Wir messen dem Datenschutz große Bedeutung bei. Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden datenschutzrechtlichen Vorschriften," +
        "insbesondere der Datenschutzgrundverordnung (DSGVO)."+
        "\n\n1. Verantwortlicher\nVerantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist die Aixioom Software Solutions GmbH, " +
        "Katharinenweg 20, 63762 Großostheim, Deutschland, vertreten durch die Geschäftsführer Stefan Herbst und Fabio Wehmer." +
        "\nTel.: +49 (0) 6026 5013338" +
        "\nE-Mail: info@aixioom.de" +
        "\nSofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen wollen, " +
        "können Sie Ihren Widerspruch an den Verantwortlichen richten. Sie können diese Datenschutzerklärung jederzeit speichern und ausdrucken." + 
        "\n\n2. Allgemeine Zwecke der Verarbeitung" + 
        "\nWir verwenden personenbezogene Daten zum Zweck des Betriebs der Website und zur Erfüllung unserer Pflichten aus den zwischen uns und unseren Kunden bestehenden Verträgen." + 
        "\n\n3. Welche Daten wir verwenden und warum" + 
        "\n3.1. Zugriffsdaten" + 
        "\nWir sammeln Informationen über Sie, wenn Sie diese Website nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und " +
        "registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Website (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:" + 
        "\n- Name und URL der abgerufenen Datei" + 
        "\n- Datum und Uhrzeit des Abrufs" + 
        "\n- übertragene Datenmenge" + 
        "\n- Meldung über erfolgreichen Abruf (HTTP response code)" +
        "\n- Browsertyp und Browserversion" +
        "\n- Betriebssystem" + 
        "\n- Referer URL (d.h. die zuvor besuchte Seite)" + 
        "\n- Websites, die vom System des Nutzers über unsere Website aufgerufen werden" + 
        "\n- Internet-Service-Provider des Nutzers" + 
        "\n- IP-Adresse und der anfragende Provider" + 
        "\nWir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website," +
        " aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste, ebenso zu Abrechnungszwecken, " + 
        "um die Anzahl der von Kooperationspartnern erhaltenen Clicks zu messen. Aufgrund dieser Informationen können wir personalisierte und standortbezogene Inhalte zur Verfügung stellen und den Datenverkehr analysieren," +
        " Fehler suchen und beheben und unsere Dienste verbessern." +
        "\nHierin liegt auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO." + 
        "\nWir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht." +
        " IP-Adressen speichern wir für einen begrenzten Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke erforderlich oder für die Leistungserbringung oder die Abrechnung einer Leistung nötig ist," +
        " z. B. wenn Sie eines unserer Angebote nutzen. Nach Abbruch des Vorgangs der Bestellung oder nach Zahlungseingang löschen wir die IP-Adresse, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist." +
        " IP-Adressen speichern wir auch dann, wenn wir den konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben. " + 
        "Außerdem speichern wir als Teil Ihres Accounts das Datum Ihres letzten Besuchs (z.B. bei Registrierung, Login, Klicken von Links etc.)." + 
        "\n\n3.2. Cookies" + 
        "\nWir verwenden sogenannte Session-Cookies, um unsere Website zu optimieren. Ein Session-Cookie ist eine kleine Textdatei, " + 
        "die von den jeweiligen Servern beim Besuch einer Internetseite verschickt und auf Ihrer Festplatte zwischengespeichert wird. Diese Datei als solche enthält eine sogenannte Session-ID," + 
        " mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. " + 
        "Diese Cookies werden gelöscht, nachdem Sie Ihren Browser schließen. Sie dienen z. B. dazu, dass Sie die Warenkorbfunktion über mehrere Seiten hinweg nutzen können." + 
        "\nWir verwenden in geringem Umfang auch persistente Cookies (ebenfalls kleine Textdateien, die auf Ihrem Endgerät abgelegt werden)," + 
        " die auf Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit von allein." + 
        " Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre. So können wir Ihnen unser Angebot nutzerfreundlicher," + 
        " effektiver und sicherer präsentieren und Ihnen beispielsweise speziell auf Ihre Interessen abgestimmte Informationen auf der Seite anzeigen. " + 
        "Unser berechtigtes Interesse an der Nutzung der Cookies gemäß Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Website nutzerfreundlicher, effektiver und sicherer zu machen." + 
        "\nIn den Cookies werden etwa folgende Daten und Informationen gespeichert:" + 
        "\n- Log-In-Informationen" + 
        "\n- Spracheinstellungen" +
        "\n- eingegebene Suchbegriffe" +
        "\n- Informationen über die Anzahl der Aufrufe unserer Website sowie Nutzung einzelner Funktionen unseres Internetauftritts." +
        "\nBei Aktivierung des Cookies wird diesem eine Identifikationsnummer zugewiesen und eine Zuordnung Ihrer personenbezogenen Daten zu dieser Identifikationsnummer wird nicht vorgenommen." +
        " Ihr Name, Ihre IP-Adresse oder ähnliche Daten, die eine Zuordnung des Cookies zu Ihnen ermöglichen würden, werden nicht in den Cookie eingelegt." +
        " Auf Basis der Cookie-Technologie erhalten wir lediglich pseudonymisierte Informationen, beispielsweise darüber, welche Seiten unseres Shops besucht wurden, welche Produkte angesehen wurden, etc." +
        "\nSie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können," +
        " ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Website eingeschränkt werden." +
        "\n\n3.3. Analyse Tools" +
        "\nWir nutzen auf unserer Webseite den Webseiten-Analysedienst für Webseiten von Google Analytics, ein Webanalysedienst der Google Inc. (https://www.google.de/intl/de/about/) " + 
        "(1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet." + 
        " Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie" + 
        "\n- Browser-Typ/-Version," + 
        "\n- verwendetes Betriebssystem," + 
        "\n- Referrer-URL (die zuvor besuchte Seite)," + 
        "\n- Hostname des zugreifenden Rechners (IP-Adresse)," + 
        "\n- Uhrzeit der Serveranfrage," + 
        "\nwerden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten," +
        " um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung" +
        " dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten." +
        " Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking)." +
        "Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, " +
        "dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können." +
        "\nSie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern," +
        " indem Sie ein Browser-Add-on herunterladen und installieren (https://tools.google.com/dlpage/gaoptout?hl=de)." +
        "\nWeitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (https://support.google.com/analytics/answer/6004245?hl=de)" +
        "\nBei Nutzung des Dienstes Google Adwords Conversion Tracking wird, sofern Sie über eine Anzeige bei Google auf diese Seite gelangt sind, ein Cookie auf Ihrem Gerät gesetzt." +
        " Dies dient der Analyse und statistischen Auswertung der Nutzung der Webseite. Das Cookie ist 30 Tage gültig. Eine Identifizierung der Person erfolgt nicht, allerdings die Beobachtung, " +
        "ob der Nutzer über eine Google Werbeanzeige auf die Seite gelangt ist." +
        "\nDiese Nutzung können Sie verhindern, indem Sie in den Einstellungen Ihres Browsers das Setzen entsprechender Cookies ablehnen." +
        "\n\n3.4. Daten zur Erfüllung unserer vertraglichen Pflichten" +
        "\nWir verarbeiten personenbezogene Daten, die wir zur Erfüllung unserer vertraglichen Pflichten benötigen, etwa Name, Adresse, E-Mail-Adresse,  Geburtsdatum, Rechnungs- und Zahlungsdaten." +
        " Die Erhebung dieser Daten ist für den Vertragsschluss erforderlich." +
        "\nDie Löschung der Daten erfolgt nach Ablauf der gesetzlichen Aufbewahrungsfristen." +
        "\nDie Rechtgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 S. 1 b) DSGVO, denn diese Daten werden benötigt, damit wir unsere vertraglichen Pflichten Ihnen gegenüber erfüllen können." +
        "\n\n3.5. E-Mail Kontakt" +
        "\nWenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail), verarbeiten wir Ihre Angaben zur Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen." +
        "\nErfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, bzw., wenn Sie bereits unser Kunde sind, zur Durchführung des Vertrages," + 
        " ist Rechtsgrundlage für diese Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO." +
        "\nWeitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder wir ein berechtigtes Interesse an der Verarbeitung Ihrer Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO)." + 
        " Ein berechtigtes Interesse liegt z. B. darin, auf Ihre E-Mail zu antworten." +
        "\n\n4. Speicherdauer" +
        "\nSofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist." +
        "In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. " +
        "In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht." +
        "\n\n5. Ihre Rechte als von der Datenverarbeitung Betroffener" +
        "\nNach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen," +
        " so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 1 genannte Adresse." +
        "\nNachfolgend finden Sie eine Übersicht über Ihre Rechte." +
        "\n\n5.1. Recht auf Bestätigung und Auskunft" +
        "\nSie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten." +
        "\nIm Einzelnen:" + 
        "\nSie haben jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. " +
        "Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. " +
        "Des Weiteren besteht ein Recht auf folgende Informationen:" +
        "\n- die Verarbeitungszwecke;" +
        "\n- die Kategorien personenbezogener Daten, die verarbeitet werden;" +
        "\n- die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;" +
        "\n- falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;" +
        "\n- das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;" +
        "\n- das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;" +
        "\n- wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;" +
        "\n- das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß  22Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über " +
        "die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie." +
        "\nWerden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden." +
        "\n\n5.2. Recht auf Berichtigung" +
        "\nSie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen." +
        "\nIm Einzelnen:" +
        "\nSie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. " +
        "Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen." +
        "\n\n5.3. Recht auf Löschung ('Recht auf Vergessenwerden')" +
        "\nIn einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen." +
        "\nIm Einzelnen:" +
        "\nSie haben gemäß Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:" +
        "\n- Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig." +
        "\n- Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6Abs. 1 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung." +
        "\n- Sie legen gemäß Art. 21Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein." +
        "\n- Die personenbezogenen Daten wurden unrechtmäßig verarbeitet." +
        "\n- Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen." +
        "\n- Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8Abs. 1 DSGVO erhoben." +
        "\nHaben wir die personenbezogenen Daten öffentlich gemacht und sind wir gemäß Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, " +
        "so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, " +
        "die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder " +
        "Replikationen dieser personenbezogenen Daten verlangt haben." +
        "\n\n5.4. Recht auf Einschränkung der Verarbeitung" +
        "\nIn einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen." +
        "\nIm Einzelnen:" +
        "\nSie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:" +
        "\n- die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen," +
        "\n- die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;" +
        "\n- wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder" +
        "\n- Sie Widerspruch gegen die Verarbeitung gemäß Art. 21Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen." +
        "\n\n5.5. Recht auf Datenübertragbarkeit" +
        "\nSie haben das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu lasen." +
        "\nIm Einzelnen:" +
        "\nSie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, " + 
        "diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern" +
        "\n- die Verarbeitung auf einer Einwilligung gemäß Art. 6Abs. 1 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 S. 1 b) DSGVO beruht und" +
        "\n- die Verarbeitung mithilfe automatisierter Verfahren erfolgt." +
        "\nBei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist." +
        "\n\n5.6. Widerspruchsrecht" +
        "\nSie haben das Recht, aus einer rechtmäßigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen." +
        "\nIm Einzelnen:" +
        "\nSie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, " +
        "Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, " +
        "wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, " +
        "Ausübung oder Verteidigung von Rechtsansprüchen. Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, " +
        "jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht." +
        "\nSie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Sie betreffender personenbezogener Daten, " +
        "die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn, " +
        "die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich." +
        "\n\n5.7. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung" +
        "\nSie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen." +
        "\n\n5.8. Recht auf Beschwerde bei einer Aufsichtsbehörde" +
        "\nSie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, " +
        "wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist." +
        "\n\n6. Datensicherheit" +
        "\nWir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht. Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. " +
        "Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch darauf hin, " +
        "dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich." +
        "\nZur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen." +
        "\nWir gewährleisten außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmäßig sorgfältig gesichert." +
        "\n\n7. Weitergabe von Daten an Dritte, keine Datenübertragung ins Nicht-EU-Ausland" +
        "\nGrundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb der Aixioom Software Solutions GmbH." +
        "\nWenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Fra-Dienstleister), erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist." +
        "\nFür den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, " +
        "personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten." +
        "\nEine Datenübertragung an Stellen oder Personen außerhalb der EU außerhalb des in dieser Erklärung in Ziffer 4 genannten Falls findet nicht statt und ist nicht geplant." +
        "\n\n8. Datenschutzbeauftragter" +
        "\nSollten Sie noch Fragen oder Bedenken zum Datenschutz haben, so wenden Sie sich bitte an die Aixioom Software Solutions GmbH vertreten durch Herrn Fabio Wehmer und Stefan Herbst."}/>
    </div>
  )
};
export default DataPrivacy;
  