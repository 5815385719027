//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorTransportation.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterTransport from "../../components/CompValuesCenterTransport";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageTransportation from '../global/images/Transportlogistik.png'


//SectorTransportation_____________________________________________
const SectorTransportation = () => {

  return (
    <div className="SectorTransportation">
      <CompSectorPicture Title={"Transportlogistik"} 
      Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand  – dank intelligenter Prognose Ihrer Tonnagen mit künstlicher Intelligenz."} 
      Picture={ImageTransportation} PictureAlt={"Transportlogistik"}/>
      <div className="SectorTransportation-Content page-gap">
        <CompValuesCenterTransport Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für die Transportlogistik"} Subheading={""}
          ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
          ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP- und MES-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} 
        ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorTransportation;
  