//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesCenter.css'

// Graphics
import GraphicRevenue from '../pages/global/icons/Icon_GrowingRevenue.svg'
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg';
import GraphicWaste from '../pages/global/icons/Icon_ReduceWaste.svg';
import GraphicFastPlanning from '../pages/global/icons/Icon_FastPlanning.svg';

// Components
import CompIconTextCenter from "./CompIconTextCenter"
import FadeInSection from "./utils/FadeInSection";

//CompValuesCenterBakery______________________________________________________________________________
const CompValuesCenterBakery = (props) => {
    return (
        <div className="CompValuesCenter-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
                <div className="CompValuesCenter-Container-Icons">
                    <CompIconTextCenter Graphic={GraphicRevenue} 
                    Subheading={"Schöpfen Sie das Verkaufspotential durch optimale Warenverfügbarkeit voll aus und  steigern Sie so Ihren Umsatz durchschnittlich um 2.000€ pro Filiale"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicHappyCustomer} Subheading={"Erhöhung der Kundenzufriedenheit durch optimale Warenverfügbarkeit"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicWaste} Subheading={"Reduzierung Ihrer Retouren um durchschnittlich 30 bis 40%"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicFastPlanning} Subheading={"95% Zeitersparnis in der operativen Planung durch Automatisierung von Routineaufgaben"} Text={""}/>
                </div>
        </div>
    )
};
export default CompValuesCenterBakery;
  