//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompCustomerVoice.css'

//CompCustomerVoice______________________________________________________________________________
const CompCustomerVoice = (props) => {

    return (
        <div className="CompCustomerVoice-Container">
            <h2>{props.Title}</h2>
            <div className="CompCustomerVoice-ImgText">
                <img src={props.CustomerImg} alt={props.GraphicAlt}/>
                <p>{props.Text}</p>
            </div>
        </div>
    )
};
export default CompCustomerVoice;
  