//IMPORT________________________________________________
import React from "react";

// CSS
import './SolutionForecast.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompPictureTextLeft from "../../components/CompPictureTextLeft";
import CompPictureText from "../../components/CompPictureText";
import CompValuesForecast from "../../components/CompValuesForecast";
import CompCallToAction from "../../components/CompCallToAction";
import CompHeadingText from "../../components/CompHeadingText";

// Graphics
import GraphicForecast from '../global/graphics/Forecast.svg'
import GraphicForecastSales from '../global/images/Aixioom-Forecast-Screenshot-Sales.png'
import GraphicForecastData from '../global/images/Aixioom-Forecast-Screenshot-Data.png'
import GraphicForecastArticle from '../global/images/Aixioom-Forecast-Screenshot-Article.png'

//SolutionForecast_____________________________________________
const SolutionForecast = () => {

  return (
    <div className="SolutionForecast-Content page-gap">
      <CompHeading Title={"Forecast"} Subheading={"Bereits heute wissen, was morgen verkauft wird."}/>
      <CompPictureText Title={"Präzise Bedarfsplanung"} Subheading={""} Text={"Mit unserer fortschrittlichen KI-Technologie können Sie mit Hilfe hunderter Einflussfaktoren die Bedarfe nach " + 
        "Ihren Produkten präzise vorhersagen. \n\nDas Herzstück von Forecast ist eine Künstliche Intelligenz, mit der automatisch und präzise die ideale Bestellmenge für jede Ihrer Filialen ermittelt wird."} 
        Graphic={GraphicForecast} GraphicAlt={"Präzise Bedarfsplanung"}/>
      <CompValuesForecast Title={"Vorteile"} Subheading={""}/>
      <div className="SolutionForecast-Content-SW-Feature">
        <CompHeadingText Title={"Funktionen"}/>
        <div className="SolutionForecast-Content-SW-Feature-Content page-gap-small">
          <CompPictureTextLeft Title={"Präzise Vorhersage Ihrer Absatzmengen"} Subheading={""} Text={"Forecast ermittelt exakt Ihre Absätze für jeden Standort auf Artikelebene."} 
          Graphic={GraphicForecastSales}/>
          <CompPictureText Title={"Mehr Einblick in Ihre Daten"} Subheading={""} Text={"Behalten Sie mit Forecast alle relevanten KPI’s wie Retourenquote, " + 
          "Stockouts oder Umsatz Ihrer Artikel für jeden Standort mühelos im Blick."} 
          Graphic={GraphicForecastData}/>
          <CompPictureTextLeft Title={"Sortimentsanalyse"} Subheading={""} Text={"Forecast zeigt auf, wie sich der Verkauf Ihrer Artikel entwickelt und schlägt Anpassungen des Sortiments auf Standortebene vor."} 
          Graphic={GraphicForecastArticle}/>
        </div>
      </div>
      <CompCallToAction Title={"Starten Sie noch heute mit Aixioom"} Subheading={"Kontaktieren Sie jetzt eine(n) unserer Expert*innen und fordern Sie eine individuelle Demo an."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
    </div>
  )
};
export default SolutionForecast;
  