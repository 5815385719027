//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorEngineering.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterEngineering from "../../components/CompValuesCenterEngineering";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageEngineering from '../global/images/Maschinen_und_Anlagenbau.png'


//SectorEngineering_____________________________________________
const SectorEngineering = () => {

  return (
    <div className="SectorEngineering">
      <CompSectorPicture Title={"Maschinen- und Anlagenbau"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand  – dank intelligenter Planung mit künstlicher Intelligenz."} Picture={ImageEngineering} PictureAlt={"Maschinen- und Anlagenbau"}/>
      <div className="SectorEngineering-Content page-gap">
        <CompValuesCenterEngineering Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für den Maschinen- und Anlagenbau"} Subheading={""}
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP- und MES-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorEngineering;
  