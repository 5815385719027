//IMPORT_______________________________________________________________________
import React from "react";

// Links für Router
import { Link } from "react-router-dom";

// CSS
import './ButtonGreen.css'

//ButtonGreen__________________________________________________________________
const ButtonGreen = (props) => {
    return (
        <Link className="ButtonGreen-Container" to={"/contact"}>
            <div className="ButtonGreen-Container-Text">{props.Text}</div>
        </Link>
    )
}; 
export default ButtonGreen;
  