//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompLogoText.css'

//CompLogoText______________________________________________________________________________
const CompLogoText = (props) => {
    return (
        <div className="CompLogoText-Container">
            <div className="CompLogoText-Container-Left">
                <img src={props.Graphic} alt={props.GraphicAlt}/>
            </div>
            <div className="CompLogoText-Container-Right">
                <div className="CompLogoText-Container-Right-Title">
                    <h2>{props.Title}</h2>
                    <h3>{props.Subheading}</h3>
                </div>
{/*                 <div className="CompLogoText-Container-Right-Text">
                    <p>{props.Text}</p>
                </div> */}
            </div>
        </div>
    )
};
export default CompLogoText;
  