//IMPORT_______________________________________________________________________________________
import React from "react";

// Link
import { Link } from "react-router-dom";

// CSS
import './CompHeadingTextMail.css'

//CompHeadingTextMail______________________________________________________________________________
const CompHeadingTextMail = (props) => {
    return (
        <div className="CompHeadingTextMail-Container">
            <h2>{props.Title}</h2>
            <div className="CompHeadingTextMail-Container-Text">
                <p>{props.Company}</p>
                <p>Telefon: {props.Phone}</p>
                <p>Mail: <Link to={"mailto:" + props.Mail}>{props.Mail}</Link></p>
            </div>
        </div>
    )
};
export default CompHeadingTextMail;
  