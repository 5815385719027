//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesSOP.css'

// Graphics
import GraphicGant from '../pages/global/icons/Icon_Gant.svg';
import GraphicTime from '../pages/global/icons/Icon_Time.svg';
import GraphicCalender from '../pages/global/icons/Icon_CalenderTime.svg';
import GraphicTool from '../pages/global/icons/Icon_FastToolChange.svg';

// Components
import CompIconText from "./CompIconText";

//CompValuesSOP______________________________________________________________________________
const CompValuesSOP = (props) => {
    return (
        <div className="CompValuesSOP-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <div className="CompValuesSOP-Container-Icons">
                <CompIconText Graphic={GraphicGant} Subheading={"Reduktion des Planungsaufwands um bis zu 50%"} 
                Text={"A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like."}/>
                <CompIconText Graphic={GraphicTime} Subheading={"Erhöhung der Kundenzufriedenheit durch optimierte Verfügbarkeit"} 
                Text={"Aixioom PP garanierte eine signifikante Reduktion von Durchlaufzeiten"}/>
                <CompIconText Graphic={GraphicCalender} Subheading={"Steigerung der Termintreue"} 
                Text={"A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like. A subheading for this section, as long or as short as you like."}/>
                <CompIconText Graphic={GraphicTool} Subheading={"Verkürzen Sie die Umrüstzeiten um bis zu 40%"} 
                Text={"Forecast ermittelt anhand von hunderten verschiedenen Einflussfaktoren wie Wetter, wirtschaftlische Bedungungen, Ferien- und Feiertage, " + 
                "Feste und Veranstalltungen automatisch und präzise Iher Bedarfe und minimiert Risiken so proaktiv."}/>
            </div>
        </div>
    )
};
export default CompValuesSOP;
  