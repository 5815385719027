// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Career .CompHeadingText-Container h2 {
    white-space: break-spaces;
}

.Career-Accordion p {
    font-family: "Poppins", sans-serif;
    font-style: var(--font-style-aixioom-large-text);
    font-weight: var(--font-weight-aixioom-large-text);
    line-height: var(--line-height-aixioom-large-text);
}

@media (min-width: 1501px) {
    .Career .CompHeadingText-Container p {
        font-size: 28px;
    }
    .Career-Accordion p {
        font-size: 28px;
    }
}
@media (max-width: 1500px) {
    .Career .CompHeadingText-Container p {
        font-size: 22px;
    }
    .Career-Accordion p {
        font-size: 22px;
    }
}
@media (max-width: 500px) {
    .Career .CompHeadingText-Container {
        margin-bottom: 50px;
      }
    .Career .CompHeadingText-Container p {
        font-size: 16px;
    }
    .Career-Accordion p {
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/career/Career.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kCAAkC;IAClC,gDAAgD;IAChD,kDAAkD;IAClD,kDAAkD;AACtD;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,mBAAmB;MACrB;IACF;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Career .CompHeadingText-Container h2 {\n    white-space: break-spaces;\n}\n\n.Career-Accordion p {\n    font-family: \"Poppins\", sans-serif;\n    font-style: var(--font-style-aixioom-large-text);\n    font-weight: var(--font-weight-aixioom-large-text);\n    line-height: var(--line-height-aixioom-large-text);\n}\n\n@media (min-width: 1501px) {\n    .Career .CompHeadingText-Container p {\n        font-size: 28px;\n    }\n    .Career-Accordion p {\n        font-size: 28px;\n    }\n}\n@media (max-width: 1500px) {\n    .Career .CompHeadingText-Container p {\n        font-size: 22px;\n    }\n    .Career-Accordion p {\n        font-size: 22px;\n    }\n}\n@media (max-width: 500px) {\n    .Career .CompHeadingText-Container {\n        margin-bottom: 50px;\n      }\n    .Career .CompHeadingText-Container p {\n        font-size: 16px;\n    }\n    .Career-Accordion p {\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
