//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorBakery.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterBakery from "../../components/CompValuesCenterBakery";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import ImageBakery from '../global/images/Bakery.jpg'

//SectorBakery_____________________________________________
const SectorBakery = () => {

  return (
    <div className="SectorBakery">
      <CompSectorPicture Title={"Bäckereien"} Subheading={"Entdecken Sie mit unserer KI-Lösung die Zukunft der Planung! Mit unserer Künstlichen Intelligenz können Unternehmen die Zukunft mit Zuversicht planen."} 
      Picture={ImageBakery} PictureAlt={"Bäckereibetriebe"}/>
      <div className="SectorBakery-Content page-gap">
        <CompValuesCenterBakery Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für Bäcker"} Subheading={""} ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus, reduzieren gleichzeitig den Planungsaufwand und Ihre Retouren"}
         ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen Warenwirtschafts- & Kassensystemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
      Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorBakery;
  