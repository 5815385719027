//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompValuesCenter.css'

// Graphics
import GraphicRevenue from '../pages/global/icons/Icon_GrowingRevenue.svg'
import GraphicHappyCustomer from '../pages/global/icons/Icon_HappyCustomer.svg'
import GraphicCommerce from '../pages/global/icons/Icon_ReduceStockOutComerce.svg'
import GraphicFastPlanning from '../pages/global/icons/Icon_FastPlanning.svg'
import GraphicPharmacy from '../pages/global/icons/Icon_Pharmacy.svg'

// Components
import CompIconTextCenter from "./CompIconTextCenter"
import FadeInSection from "./utils/FadeInSection";

//CompValuesCenterPharmacies______________________________________________________________________________
const CompValuesCenterPharmacies = (props) => {
    return (
        <div className="CompValuesCenter-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
                <div className="CompValuesCenter-Container-Icons">
                    <CompIconTextCenter Graphic={GraphicRevenue} Subheading={"Ertrag um bis zu 5% steigern und sichern"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicHappyCustomer} Subheading={"Erhöhung der Kundenzufriedenheit durch optimale Warenverfügbarkeit"} Text={""}/>
                    <CompIconTextCenter Graphic={GraphicCommerce} Subheading={"Neinverkäufe vermeiden und Besorgerquote senken"} Text={""}/>   
                    <CompIconTextCenter Graphic={GraphicFastPlanning} Subheading={"95% Zeitersparnis in der operativen Planung durch Automatisierung von Routineaufgaben"} Text={""}/> 
                    <CompIconTextCenter Graphic={GraphicPharmacy} Subheading={"Lieferfähigkeit optimieren"} Text={""}/> 
                </div>
        </div>
    )
};
export default CompValuesCenterPharmacies;
  