//IMPORT________________________________________________
import React from "react";

// CSS
import './SectorWholesale.css';

// Components
import CompHeadingText from "../../components/CompHeadingText";
import CompCallToAction from "../../components/CompCallToAction";
import CompSectorPicture from "../../components/CompSectorPicture";
import CompValuesCenterRetail from "../../components/CompValuesCenterRetail";
import CompSolutions from "../../components/CompSolutions";

// Graphics
import sectorWholesaleImg from '../global/images/Großhandel.jpg';


//SectorWholesale_____________________________________________
const SectorWholesale = () => {

  return (
    <div className="SectorWholesale">
      <CompSectorPicture Title={"Großhandel"} Subheading={"Maximale Umsätze, minimale Kosten bei minimalem Planungsaufwand – dank intelligenter Prognose Ihrer Bedarfe mit künstlicher Intelligenz."} Picture={sectorWholesaleImg} PictureAlt={"Großhandel"}/>
      <div className="SectorWholesale-Content page-gap">
        <CompValuesCenterRetail Title={"Mehrwerte"} Subheading={""}/>
        <CompSolutions Title={"Unsere innovativen Lösungen für den Großhandel"} Subheading={""} 
        ButtonText={"Mit Forecast schöpfen Sie Verkaufspotentiale voll aus und reduzieren gleichzeitig Ihre Überbestände und Stockouts"}
        ForecastDocLink={"/contact"}/>
        <CompHeadingText Title={"Kompatibel mit allen gängigen ERP-Systemen"}/>
        <CompCallToAction Title={"Mit unseren Branchenexpert*innen in Verbindung treten"} 
        Subheading={"Erfahren Sie, wie unsere innovativen Branchenlösungen eine kosteneffiziente Transformation und nachhaltiges Wachstum fördern."} ButtonText={"Kontakt"} ButtonLink={"/contact"}/>
      </div>
    </div>
  )
};
export default SectorWholesale;
  