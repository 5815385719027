// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home-Headline h1 {
    font-size: var(--font-size-aixioom-medium-h1);
    color: var(--color-aixioom-blue-main);
}`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,qCAAqC;AACzC","sourcesContent":[".Home-Headline h1 {\n    font-size: var(--font-size-aixioom-medium-h1);\n    color: var(--color-aixioom-blue-main);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
