//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompPictureTextLeft.css'

//CompPictureTextLeft______________________________________________________________________________
const CompPictureTextLeft = (props) => {
    return (
        <div className="CompPictureTextLeft-Container">
            <div className="CompPictureTextLeft-Container-Left">
                <div className="CompPictureTextLeft-Container-Left-Title">
                    <h2>{props.Title}</h2>
                    <h3>{props.Subheading}</h3>
                </div>
                <div className="CompPictureTextLeft-Container-Left-Text">
                    <p>{props.Text}</p>
                </div>
            </div>
            <div className="CompPictureTextLeft-Container-Right">
                <img src={props.Graphic} alt={props.GraphicAlt}/>
            </div>

        </div>
    )
};
export default CompPictureTextLeft;
  