//IMPORT________________________________________________
import React from "react";

// CSS
import './SolutionPP.css';

// Components
import CompHeading from '../../components/CompHeading' 
import CompPictureTextLeft from "../../components/CompPictureTextLeft";
import CompPictureText from "../../components/CompPictureText";
import CompValuesPP from "../../components/CompValuesPP";
import CompCallToAction from "../../components/CompCallToAction";
import CompHeadingText from "../../components/CompHeadingText";

// Graphics
import GraphicPP from '../global/graphics/ProductionPlanning.svg'
import GraphicForecastSales from '../global/images/Aixioom-Forecast-Screenshot-Sales.png'
import GraphicForecastData from '../global/images/Aixioom-Forecast-Screenshot-Data.png'
import GraphicForecastArticle from '../global/images/Aixioom-Forecast-Screenshot-Article.png'

//SolutionPP_____________________________________________
const SolutionPP = () => {

  return (
    <div className="SolutionPP-Content page-gap">
      <CompHeading Title={"Production Planning (PP)"} Subheading={"Intelligente Produktionsplanung für maximale Effizienz."}/>
      <CompPictureText Title={"Intelligente Produktionsplanung"} Subheading={""} Text={"Unsere KI-Lösung für die Produktionsplanung garantiert eine signifikante Reduktion von " + 
      "Durchlaufzeiten und steigert die Termintreue. Engpässe werden vermieden und Ressourcen effizient genutzt. So erreichen Sie eine optimale Auslastung Ihrer Produktion."} 
        Graphic={GraphicPP} GraphicAlt={"Intelligente Produktionsplanung"}/>
      <CompValuesPP Title={"Vorteile"} Subheading={""}/>
      <CompHeadingText Title={"Production Planning ist ab Q1 2025 verfügbar."} Subheading={""}/>
{/*       <div className="SolutionPP-Content-SW-Feature">
        <CompHeadingText Title={"Funktionen"}/>
        <div className="SolutionPP-Content-SW-Feature-Content page-gap-small">
          <CompPictureTextLeft Title={"Optimale Losgröße"} Subheading={""} Text={"AAA"} 
          Graphic={GraphicForecastSales}/>
          <CompPictureText Title={"AAA"} Subheading={""} Text={"AAA"} 
          Graphic={GraphicForecastData}/>
        </div>
      </div> */}
    </div>
  )
};
export default SolutionPP;
  