// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Customer-Content-Examples {
    display: grid;
    gap: 50px;
}

@media(min-width: 551px) {
  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,
  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {
    height: 280px;
  }
}
@media(max-width: 550px) { 
  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,
  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {
    height: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/customer/Customer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;EACE;;IAEE,aAAa;EACf;AACF;AACA;EACE;;IAEE,aAAa;EACf;AACF","sourcesContent":[".Customer-Content-Examples {\n    display: grid;\n    gap: 50px;\n}\n\n@media(min-width: 551px) {\n  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,\n  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {\n    height: 280px;\n  }\n}\n@media(max-width: 550px) { \n  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,\n  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {\n    height: 180px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
