// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sustainability-Image {
    width: 100%;
    border-radius: var(--border-radius-aixioom-large-element);
}`, "",{"version":3,"sources":["webpack://./src/pages/sustainability/Sustainability.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yDAAyD;AAC7D","sourcesContent":[".Sustainability-Image {\n    width: 100%;\n    border-radius: var(--border-radius-aixioom-large-element);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
