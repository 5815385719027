//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './CompSolutions.css'

// Components
import CompTextButtons from "./CompTextButtons";

//CompSolutions______________________________________________________________________________
const CompSolutions = (props) => {
    return (
        <div className="CompSolutions-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <div className="CompSolutions-Container-Buttons">
                <CompTextButtons Subheading={"Forecast"} Text={props.ButtonText} 
                ButtonIconGreyLink={props.ForecastDocLink} ButtonIconGreyText={"Download Produktbroschüre"} ButtonGreyLink={"/forecast"} ButtonGreyText={"Zur Lösung"}/>
            </div>
        </div>
    )
};
export default CompSolutions;
  