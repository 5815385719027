//IMPORT________________________________________________
import React from "react";

// CSS
import './Header.css'

// Links für Router
import { Link,useLocation } from "react-router-dom";

// Components & Pages
import LanguageMenu from './Header_Menus/LanguageMenu.jsx'

// Icons
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

//IMPORT END____________________________________________


//Header________________________________________________

const Header = (props) => {
  const [navbar, setNavbar] = React.useState(true);
  const collapseNavbar = () => setNavbar(!navbar);

  return (
    <div className="Navbar">
      <div className="Navbar-Search">
        <SearchRoundedIcon/>
      </div>
      <div className="Navbar-Logo">
        <Link onClick={() => {props.activeMenu("/"); !navbar ? collapseNavbar(): "";}} to="/"><img src={require("./logos/aixioom-logo.png")} alt={"Aixioom Logo"}/></Link>
      </div>
      <ul className={navbar ? "Navbar-Menu-Horizontal" : "Navbar-Menu-Vertical"}>
        <li onClick={() => {props.activeMenu("/platform"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/platform" ? "activeMenuItem" : "MenuItems"}><Link to="/platform">Plattform</Link></li>
        <li onClick={() => {props.activeMenu("/solutions"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/solutions" ? "activeMenuItem" : "MenuItems"}><Link to="/solutions">Lösungen</Link></li>
        <li onClick={() => {props.activeMenu("/sectors"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/sectors" ? "activeMenuItem" : "MenuItems"}><Link to="/sectors">Branchen</Link></li>
        <li onClick={() => {props.activeMenu("/customer"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/customer" ? "activeMenuItem" : "MenuItems"}><Link to="/customer">Kunden</Link></li>
        <li onClick={() => {props.activeMenu("/sustainability"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/sustainability" ? "activeMenuItem" : "MenuItems"}><Link to="/sustainability">Nachhaltigkeit</Link></li>
        <li onClick={() => {props.activeMenu("/about-us"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/about-us" ? "activeMenuItem" : "MenuItems"}><Link to="/about-us">Über uns</Link></li>
        <li onClick={() => {props.activeMenu("/career"); !navbar ? collapseNavbar(): "";}} className={props.menuID == "/career" ? "activeMenuItem" : "MenuItems"}><Link to="/career">Karriere</Link></li>
      </ul>
      <div className={navbar ? "Navbar-Menu-Burger-Open" : "Navbar-Menu-Burger-Closed"}>
        {navbar ? <MenuRoundedIcon onClick={() => collapseNavbar()}/> : <CloseRoundedIcon onClick={() => collapseNavbar()}/>}
      </div>
    </div>
  )
};
  
export default Header;
  