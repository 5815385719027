//IMPORT_______________________________________________________________________________________
import React from "react";

// CSS
import './TeaserSectors.css'

// Graphics
import IconBakery from '../../pages/global/icons/Icon_Baeckerei.svg';
import IconCommerce from '../../pages/global/icons/Icon_Commerce.svg';
import IconWarehouse from '../../pages/global/icons/Icon_Warehouse02.svg';
import IconGastro from '../../pages/global/icons/Icon_Gastro.svg';
import IconMachine from '../../pages/global/icons/Icon_Machine.svg';
import IconTruck from '../../pages/global/icons/Icon_Truck02.svg';
import IconTShirt from '../../pages/global/icons/Icon_TShirt.svg';
import IconPatient from '../../pages/global/icons/Icon_Patient02.svg';

// Components
import ButtonGrey from '../Buttons/ButtonGrey.jsx'
import FadeInSection from "../utils/FadeInSection.jsx";

//TeaserSectors______________________________________________________________________________
const TeaserSectors = (props) => {
    return (
        <div className="TeaserSectors-Container">
            <h2>{props.Title}</h2>
            <h3>{props.Subheading}</h3>
            <ButtonGrey Text={props.ButtonText} Link={props.ButtonLink}/>
            <div className="TeaserSectors-Container-Icons">
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconBakery} alt="Branche Bäckereien Icon"/>
                    <h3>Lebensmittel & Bäckereien</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconCommerce} alt="Branche Einzelhandel Icon"/>
                    <h3>Einzelhandel</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconWarehouse} alt="Branche Großhandel Icon"/>
                    <h3>Großhandel</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconGastro} alt="Branche Gastronomie Icon"/>
                    <h3>Gastronomie</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconMachine} alt="Branche Maschinen- und Anlagenbau Icon"/>
                    <h3>Maschinen- & Anlagenbau</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconTruck} alt="Branche Logistik Icon"/>
                    <h3>Logistik</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconTShirt} alt="Branche Textilwirtschaft Icon"/>
                    <h3>Textil und Mode</h3>
                </div>
                </FadeInSection>
                <FadeInSection>
                <div className="TeaserSectors-Icon">
                    <img src={IconPatient} alt="Branche Apotheken & Gesundheitswesen Icon"/>
                    <h3>Gesundheitswesen</h3>
                </div>
                </FadeInSection>
            </div>
        </div>
    )
};
export default TeaserSectors;
  