// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolutionForecast-Content .CompPictureText-Container-Left img, .SolutionForecast-Content .CompPictureTextLeft-Container-Right img  {
    object-fit: contain;
    object-position: 0 0;
    height: auto;
}
.SolutionForecast-Content-SW-Feature {
    display: grid;
    align-self: stretch;

}

@media(max-width: 929px) {
    .SolutionForecast-Content .CompPictureText-Container {
        flex-direction: column-reverse;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/solutions/SolutionForecast.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ","sourcesContent":[".SolutionForecast-Content .CompPictureText-Container-Left img, .SolutionForecast-Content .CompPictureTextLeft-Container-Right img  {\n    object-fit: contain;\n    object-position: 0 0;\n    height: auto;\n}\n.SolutionForecast-Content-SW-Feature {\n    display: grid;\n    align-self: stretch;\n\n}\n\n@media(max-width: 929px) {\n    .SolutionForecast-Content .CompPictureText-Container {\n        flex-direction: column-reverse;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
